import { IArrayButtonsShowMoreAdminTariffs, IOptions, IParametersOfAddTariffs } from './type';
import styles from './AdminTariffsAddPage.module.scss';
import cx from 'classnames';
import React from 'react';
import { ShowMore } from '../AdminUsersPage/components/ShowMore/ShowMore';
import { ShowMoreSVG } from '../../../components/UI/svg-icon/ShowMoreSvg';
import ShowMoreAdminTariffs from './components/ShowMoreAdminTariffs/ShowMoreAdminTariffs';
import { clearObject } from '../../../common/ClearObject.helper';
export const requestDataAddTariff = (
  currentRole: string,
  mainParametersInfo: IParametersOfAddTariffs[],
  carrierParametersInfo: IParametersOfAddTariffs[],
  supplierParametersInfo: IParametersOfAddTariffs[],
) => {
  return {
    organizationId: Number(mainParametersInfo[0].value),
    dateFrom: convertDataForBackend(mainParametersInfo[3].dateRange[0]),
    dateTo: convertDataForBackend(mainParametersInfo[3].dateRange[1]),
    margin: Number(mainParametersInfo[5].value),
    unit: mainParametersInfo[4].value,
    ...(currentRole === 'carrier' && {
      carrierTariffDetails: [
        clearObject({
          priceOnRequest: carrierParametersInfo[8].check,
          distanceFrom: Number(carrierParametersInfo[3].valueFrom),
          distanceTo: Number(carrierParametersInfo[3].valueTo),
          cost: Number(carrierParametersInfo[5].value),
          margin: Number(carrierParametersInfo[6].value),
          semitrailerId: Number(carrierParametersInfo[1].value),
          quarryId: carrierParametersInfo[2].value ? Number(carrierParametersInfo[2].value) : undefined,
          materialTypeId: Number(carrierParametersInfo[0].value),
        }),
      ],
    }),
    ...(currentRole === 'supplier' && {
      supplierTariffDetails: [
        clearObject({
          quarryId: Number(supplierParametersInfo[0].value),
          materialId: Number(supplierParametersInfo[2].value),
          cost: Number(supplierParametersInfo[3].value),
          margin: Number(supplierParametersInfo[4].value),
          priceOnRequest: supplierParametersInfo[6].check,
        }),
      ],
    }),
  };
};
export const convertDataForBackend = (date: string | Date) => {
  const newDate = new Date(date);

  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0');
  const day = String(newDate.getDate()).padStart(2, '0');
  debugger;
  return `${year}-${month}-${day}`;
};
export enum currentSiteEnum {
  ADD = 'add',
  EDIT = 'edit',
  ID = 'id',
}
export enum nameOfParameters {
  ORGANIZER = 'organizer',
  ROLE = 'role',
  COUNTERPARTY = 'counterparty',
  PERIOD = 'period',
  UNIT = 'unit',
  EXTRA_CHARGE = 'extraCharge',
  PRICE_REQUEST = 'priceRequest',
  TYPE_MATERIAL = 'typeMaterial',
  TYPE_BODY = 'typeBody',
  QUARRY = 'quarry',
  RANGE_INPUT = 'rangeInput',
  VOLUME = 'volume',
  COST_PRICE = 'costPrice',
  TOTAL_AMOUNT = 'totalAmount',
  PRICE_REQUEST_SECOND = 'priceRequestSecond',
  MATERIAL = 'material',
  PRICE_REQUEST_THIRD = 'priceRequestThird',
}
const optionsOfUnit: IOptions[] = [
  { value: 'тонн', label: 'Тн' },
  { value: 'м³', label: 'м³' },
];
const optionsOfRole: IOptions[] = [
  { value: 'carrier', label: 'Перевозчик' },
  { value: 'supplier', label: 'Поставщик' },
];
export const generateStyleMainParameters = parameterName => {
  const stylePeriodParameter = parameterName === nameOfParameters.PERIOD && styles.periodParameter;
  const styleUnitParameter = parameterName === nameOfParameters.UNIT && styles.unitParameter;
  const styleMargin = parameterName === nameOfParameters.EXTRA_CHARGE && styles.extraChargeParameter;
  const stylePriceRequest = parameterName === nameOfParameters.PRICE_REQUEST && styles.priceRequestParameter;

  return [styles.parameterMainContainer, stylePeriodParameter, styleUnitParameter, styleMargin, stylePriceRequest];
};
const compareSupplierSecondString = [
  nameOfParameters.COST_PRICE,
  nameOfParameters.TOTAL_AMOUNT,
  nameOfParameters.EXTRA_CHARGE,
  nameOfParameters.PRICE_REQUEST_SECOND,
];
export const generateStyleSupplierParameters = parameterName => {
  const styleSupplierPriceRequestParameter =
    parameterName === nameOfParameters.PRICE_REQUEST_THIRD && styles.priceRequestParameter;
  const styleSupplierSecondStringParameter =
    compareSupplierSecondString.includes(parameterName) && styles.supplierSecondStringParameter;
  const styleSupplierQuarryAndMarginParameter =
    parameterName === nameOfParameters.EXTRA_CHARGE && styles.supplierMarginParameter;
  return [
    styles.parameterSupplierContainer,
    styleSupplierSecondStringParameter,
    styleSupplierPriceRequestParameter,
    styleSupplierQuarryAndMarginParameter,
  ];
};
const compareCarrierSecondString = [
  nameOfParameters.VOLUME,
  nameOfParameters.TOTAL_AMOUNT,
  nameOfParameters.EXTRA_CHARGE,
  nameOfParameters.COST_PRICE,
  nameOfParameters.PRICE_REQUEST_THIRD,
];
export const generateStyleCarrierParameters = parameterName => {
  const styleCarrierRangeInputParameter =
    parameterName === nameOfParameters.RANGE_INPUT && styles.rangeInputCarrierParameter;
  const styleCarrierSecondStringParameter =
    compareCarrierSecondString.includes(parameterName) && styles.secondStringCarrierParameter;
  const styleCarrierPriceRequestParameter =
    parameterName === nameOfParameters.PRICE_REQUEST_SECOND && styles.priceRequestCarrierParameter;
  const styleCarrierMarginParameter =
    parameterName === nameOfParameters.EXTRA_CHARGE && styles.extraChargeCarrierParameter;
  return [
    styles.parameterCarrierContainer,
    styleCarrierSecondStringParameter,
    styleCarrierPriceRequestParameter,
    styleCarrierRangeInputParameter,
    styleCarrierMarginParameter,
  ];
};

export const currentStyleByRole = parameterName => {
  return {
    main: cx(...generateStyleMainParameters(parameterName)),
    supplier: cx(...generateStyleSupplierParameters(parameterName)),
    carrier: cx(...generateStyleCarrierParameters(parameterName)),
  };
};
export const mainParametersOfAddTariffs: IParametersOfAddTariffs[] = [
  {
    name: nameOfParameters.ORGANIZER,
    title: 'Организатор перевозки',
    typeComponent: 'select',
    value: '',
    options: [{ value: '1', label: 'mock' }],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.ROLE,
    title: 'Роль контрагента',
    typeComponent: 'select',
    value: '',
    options: optionsOfRole,
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.COUNTERPARTY,
    title: 'Контрагент',
    typeComponent: 'select',
    value: '',
    options: [{ value: '1', label: 'mock' }],
    placeholder: 'Выберите из списка',
    isSearch: true,
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.PERIOD,
    title: 'Период действия',
    typeComponent: 'rangePicker',
    open: false,
    dateRange: ['', ''],
    required: true,
  },
  {
    name: nameOfParameters.UNIT,
    title: 'Ед.изм.',
    typeComponent: 'select',
    value: '',
    options: optionsOfUnit,
    placeholder: 'Тн',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.EXTRA_CHARGE,
    title: 'Наценка',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: '%',
    required: true,
    limitSymbol: 10,
    disabled: false,
  },
  {
    name: nameOfParameters.PRICE_REQUEST,
    title: 'Цена под запрос',
    typeComponent: 'checkbox',
    check: false,
    required: true,
  },
];
export const carrierParametersOfAddTariffs: IParametersOfAddTariffs[] = [
  {
    name: nameOfParameters.TYPE_MATERIAL,
    title: 'Тип материала',
    typeComponent: 'select',
    value: '',
    options: [{ value: '1', label: 'mock' }],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.TYPE_BODY,
    title: 'Тип кузова',
    typeComponent: 'select',
    value: '',
    options: [{ value: '1', label: 'mock' }],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.QUARRY,
    title: 'Карьер',
    typeComponent: 'select',
    value: '',
    options: [{ value: '1', label: 'mock' }],
    placeholder: 'Выберите из списка',
    required: false,
    disabled: false,
  },
  {
    name: nameOfParameters.RANGE_INPUT,
    title: 'Расстояние',
    typeComponent: 'rangeInput',
    valueTo: '',
    valueFrom: '',
    placeholder: '0 км',
    suffix: 'км',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.VOLUME,
    title: 'Объем',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: 'тн',
    required: true,
    limitSymbol: 20,
    disabled: false,
  },
  {
    name: nameOfParameters.COST_PRICE,
    title: 'Себестоимость',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: '₽/тн',
    required: true,
    limitSymbol: 10,
    disabled: false,
  },
  {
    name: nameOfParameters.EXTRA_CHARGE,
    title: 'Наценка',
    typeComponent: 'input',
    value: '',
    placeholder: '0 %',
    suffix: '%',
    required: true,
    limitSymbol: 10,
    disabled: false,
  },
  {
    name: nameOfParameters.TOTAL_AMOUNT,
    title: 'Итоговая сумма',
    typeComponent: 'input',
    placeholder: '0 ₽/тн',
    suffix: '₽/тн',
    required: true,
    disabled: true,
    limitSymbol: 10,
  },
  {
    name: nameOfParameters.PRICE_REQUEST_SECOND,
    title: 'Цена под запрос',
    typeComponent: 'checkbox',
    check: false,
    required: true,
    disabled: false,
  },
];
export const supplierParametersOfAddTariffs: IParametersOfAddTariffs[] = [
  {
    name: nameOfParameters.QUARRY,
    title: 'Карьер',
    typeComponent: 'select',
    value: '',
    options: [{ value: '1', label: 'mock' }],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.MATERIAL,
    title: 'Материал',
    typeComponent: 'select',
    value: '',
    options: [{ value: '1', label: 'mock' }],
    placeholder: 'Выберите из списка',
    required: true,
    disabled: false,
  },
  {
    name: nameOfParameters.VOLUME,
    title: 'Объем',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: 'тн',
    required: true,
    limitSymbol: 20,
    disabled: false,
  },
  {
    name: nameOfParameters.COST_PRICE,
    title: 'Себестоимость',
    typeComponent: 'input',
    value: '',
    placeholder: 'Введите значение',
    suffix: '₽/тн',
    required: true,
    limitSymbol: 10,
    disabled: false,
  },
  {
    name: nameOfParameters.EXTRA_CHARGE,
    title: 'Наценка',
    typeComponent: 'input',
    value: '',
    placeholder: '0 %',
    suffix: '%',
    required: true,
    limitSymbol: 10,
    disabled: false,
  },
  {
    name: nameOfParameters.TOTAL_AMOUNT,
    title: 'Итоговая сумма',
    typeComponent: 'input',
    placeholder: '0 ₽/тн',
    suffix: '₽/тн',
    required: true,
    disabled: true,
    limitSymbol: 10,
  },
  {
    name: nameOfParameters.PRICE_REQUEST_THIRD,
    title: 'Цена под запрос',
    typeComponent: 'checkbox',
    check: false,
    required: true,
    disabled: false,
  },
];
export const dataResponseTableMock = [
  {
    typeMaterial: 'Щебень гравийный; гр:мытый/сеяный; фр:5-20; марка дроб.:М 800-1000; насып. пл.:1,35-1,45',
    volume: {
      value: '5000',
      unit: 'тн',
    },
    costPrice: {
      value: '5000',
      unit: 'тн/r',
    },
    extraCharge: '12%',
    totalAmount: '336 r',
    priceQuestion: 'da',
    lastChanges: {
      date: '18.12.2024 17:52',
      person: 'Иванов Петр Сергеевичdwadawdawdwadawdawdwa',
    },
  },
];
export const carrierColumnsOfSupplier = [
  {
    title: 'Тип материала',
    dataIndex: 'typeMaterial',
    width: 175,
    key: 'typeMaterial',
    render: text => (
      <div className={styles.containerColumn} style={{ marginLeft: '24px' }}>
        <p className={styles.textTypeStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Тип кузова',
    dataIndex: 'typeMaterial',
    width: 132,
    key: 'typeMaterial',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Карьер',
    dataIndex: 'typeMaterial',
    width: 152,
    key: 'typeMaterial',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Километраж',
    dataIndex: 'totalAmount',
    width: 132,
    key: 'totalAmount',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Себестоимость',
    dataIndex: 'costPrice',
    width: 152,
    key: 'costPrice',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>
          {text.value} {text.unit}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Наценка',
    dataIndex: 'extraCharge',
    width: 92,
    key: 'extraCharge',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Итого',
    dataIndex: 'totalAmount',
    width: 112,
    key: 'totalAmount',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Цена под вопрос',
    dataIndex: 'priceQuestion',
    width: 152,
    key: 'priceQuestion',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'lastChanges',
    width: 207,
    key: 'lastChanges',
    render: text => (
      <div className={styles.containerColumnLastChanges}>
        <div style={{ width: '127px' }} className={styles.lastChangeContainer}>
          <p className={styles.textStyle}>{text.date}</p>
          <p className={styles.textSoftStyle}>{text.person}</p>
        </div>
        <ShowMoreAdminTariffs />
      </div>
    ),
  },
];
export const supplierColumnsOfSupplier = [
  {
    title: 'Тип материала',
    dataIndex: 'typeMaterial',
    width: 406,
    key: 'typeMaterial',
    render: text => (
      <div className={styles.containerColumn} style={{ marginLeft: '24px' }}>
        <p className={styles.textTypeStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Объем',
    dataIndex: 'volume',
    width: 132,
    key: 'volume',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>
          {text.value} {text.unit}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Себестоимость',
    dataIndex: 'costPrice',
    width: 152,
    key: 'costPrice',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>
          {text.value} {text.unit}
        </p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Наценка',
    dataIndex: 'extraCharge',
    width: 92,
    key: 'extraCharge',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Итого',
    dataIndex: 'totalAmount',
    width: 112,
    key: 'totalAmount',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Цена под вопрос',
    dataIndex: 'priceQuestion',
    width: 152,
    key: 'priceQuestion',
    render: text => (
      <div className={styles.containerColumn}>
        <p className={styles.textStyle}>{text}</p>
        <div className={styles.border} />
      </div>
    ),
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'lastChanges',
    width: 240,
    key: 'lastChanges',
    render: text => (
      <div className={styles.containerColumnLastChanges}>
        <div style={{ width: '160px' }} className={styles.lastChangeContainer}>
          <p className={styles.textStyle}>{text.date}</p>
          <p className={styles.textSoftStyle}>{text.person}</p>
        </div>
        <ShowMoreAdminTariffs />
      </div>
    ),
  },
];
export const objectCompare = {
  add: 'Добавить тариф',
  edit: 'Редактировать тариф',
  id: 'Тариф 123098123',
};
export const arrayButtons: IArrayButtonsShowMoreAdminTariffs[] = [
  {
    title: 'Создать копию',
    onClick: () => console.log('Создать копию') /*здесь будут использоваться методы*/,
  },
  {
    title: 'Редактировать',
    onClick: () => console.log('Редактировать') /*здесь будут использоваться методы*/,
  },
  {
    title: 'Удалить',
    onClick: () => console.log('Удалить') /*здесь будут использоваться методы*/,
  },
];
