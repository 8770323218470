import React from 'react';
import styles from './ButtonSimple.module.scss';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

interface IProps {
  text?: string;
  filledText?: string;
  svg?: any;
  filledSvg?: any;
  onClick?: any;
  disabled?: boolean;
  isFilled?: boolean;
  isAnotherDisabledColor?: boolean;
  isRightSvg?: boolean;
  isText?: boolean;
  bigMobileText?: boolean;
  smallDesktopText?: boolean;
}

export const ButtonSimple = ({
  text,
  filledText = '',
  svg,
  filledSvg = '',
  onClick,
  disabled,
  isFilled = false,
  isAnotherDisabledColor = false,
  isRightSvg = false,
  isText = true,
  bigMobileText = false,
  smallDesktopText = false,
}: IProps) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <div
      className={cx(
        styles.buttonContainer,
        disabled && styles.disabled,
        isAnotherDisabledColor && styles.anotherDisabled,
      )}
      onClick={e => {
        if (!disabled) onClick(e);
      }}
    >
      {!isRightSvg && (isFilled ? <>{filledSvg}</> : <>{svg}</>)}
      {isText && (
        <p
          className={cx(styles.buttonText, bigMobileText && styles.mobileBigText, smallDesktopText && styles.smallText)}
        >
          {isFilled ? filledText : text}
        </p>
      )}
      {isRightSvg && (isFilled ? <>{filledSvg}</> : <>{svg}</>)}
    </div>
  );
};
