import React from 'react';
import RubbleSvg from 'src/components/UI/svg-icon/RubbleSvg';
import CrushingScreeningSvg from '../../components/UI/svg-icon/CrushingScreeningSvg';
import SandSvg from '../../components/UI/svg-icon/SandSvg';
import PscSvg from '../../components/UI/svg-icon/PscSvg';
import AsgSvg from '../../components/UI/svg-icon/AsgSvg';
import {
  dataCardResponseType,
  ICheckBox,
  IMaterialButtons,
  IOptions,
  ITypeInputsOfMaterial,
  ITypeKindMaterialOptions,
  ITypeMaterialsOptionsInputs,
  ITypeOfArrayInputsOfMaterial,
} from './type';
import uuid from 'react-uuid';
import RubbleFullSvg from '../../components/UI/svg-icon/RubbleFullSvg';
import CrushingScreeningFullSvg from '../../components/UI/svg-icon/CrushingScreeningFullSvg';
import SandFullSvg from '../../components/UI/svg-icon/SandFullSvg';
import PscFullSvg from '../../components/UI/svg-icon/PscFullSvg';
import AsgFullSvg from '../../components/UI/svg-icon/AsgFullSvg';
import GravelMaterialSvg from '../../components/UI/svg-icon/GravelMaterialSvg';
import GravelMaterialFullSvg from 'src/components/UI/svg-icon/GravelMaterialFullSvg';
import { regForFlakinessWithComma, regForNumberWithComma } from 'src/common/number.helper';

export const arrayMaterialButtons: IMaterialButtons[] = [
  { title: 'Щебень', svg: <RubbleSvg />, active: false, value: 'rubble', disabled: false },
  {
    title: 'Щебень вторичный',
    svg: <CrushingScreeningSvg />,
    active: false,
    value: 'secondary-crushed-stone',
    disabled: false,
  },
  { title: 'Песок', svg: <SandSvg />, active: false, value: 'sand', disabled: false },
  { title: 'ЩПС', svg: <PscSvg />, active: false, value: 'psc', disabled: false },
  { title: 'ПГС', svg: <AsgSvg />, active: false, value: 'asg', disabled: false },
  { title: 'Гравий', svg: <GravelMaterialSvg />, active: false, value: 'gravel', disabled: false },
];
export const convertValueFromNameMaterial = {
  Щебень: 'rubble',
  'Щебень вторичный': 'secondary-crushed-stone',
  Песок: 'sand',
  ЩПС: 'psc',
  ПГС: 'asg',
  Гравий: 'gravel',
};
export const convertNameFromValueMaterial = {
  rubble: 'Щебень',
  'secondary-crushed-stone': 'Щебень вторичный',
  sand: 'Песок',
  psc: 'ЩПС',
  asg: 'ПГС',
  gravel: 'Гравий',
};
export const optionDefaultForTypeMaterialSelect = {
  rubble: { value: 'rubble', label: 'Щебень' },
  'secondary-crushed-stone': { value: 'secondary-crushed-stone', label: 'Щебень вторичный' },
  sand: { value: 'sand', label: 'Песок' },
  psc: { value: 'psc', label: 'ЩПС' },
  asg: { value: 'asg', label: 'ПГС' },
  gravel: { value: 'gravel', label: 'Гравий' },
};
export const optionsSelectMaterial: IOptions[] = [
  { value: 'rubble', label: 'Щебень' },
  { value: 'secondary-crushed-stone', label: 'Щебень вторичный' },
  { value: 'sand', label: 'Песок' },
  { value: 'psc', label: 'ЩПС' },
  { value: 'asg', label: 'ПГС' },
  { value: 'gravel', label: 'Гравий' },
];
export const svgByMaterial = {
  Щебень: <RubbleFullSvg />,
  'Щебень вторичный': <CrushingScreeningFullSvg />,
  Песок: <SandFullSvg />,
  ЩПС: <PscFullSvg />,
  ПГС: <AsgFullSvg />,
  Гравий: <GravelMaterialFullSvg />,
};
export const initStateCheckBox: ICheckBox = {
  id: '',
  active: false,
};
export const initStateInputsOfMaterial: ITypeInputsOfMaterial = {
  rubble: {
    prices: [{ region: 'Все', value: '', id: uuid(), error: false }],
    hghContent: '',
    volume: '',
    description: '',
    name: '',
    sparseness: '',
    kind: '',
    frostResistance: '',
    fraction: '',
    mark: '',
    bulkCoefficient: '',
    photo: [],
    passport: [],
  },
  'secondary-crushed-stone': {
    volume: '',
    description: '',
    name: '',
    passport: [],
    photo: [],
    prices: [{ region: 'Все', value: '', id: uuid(), error: false }],
    kind: '',
    bulkCoefficient: '',
    fraction: '',
  },
  sand: {
    hghContent: '',
    prices: [{ region: 'Все', value: '', id: uuid(), error: false }],
    volume: '',
    description: '',
    name: '',
    filtrationCoefficient: '',
    coarseness: '',
    bulkCoefficient: '',
    kind: '',
    photo: [],
    passport: [],
    class: '',
    group: '',
  },
  psc: {
    hghContent: '',
    prices: [{ region: 'Все', value: '', id: uuid(), error: false }],
    volume: '',
    description: '',
    name: '',
    type: '',
    fraction: '',
    bulkCoefficient: '',
    photo: [],
    passport: [],
    mark: '',
    sparseness: '',
    frostResistance: '',
  },
  asg: {
    hghContent: '',
    prices: [{ region: 'Все', value: '', id: uuid(), error: false }],
    volume: '',
    description: '',
    name: '',
    kind: '',
    bulkCoefficient: '',
    photo: [],
    passport: [],
    mark: '',
    frostResistance: '',
    category: '',
    fraction: '',
    gravelPercent: '',
    sandPercent: '',
    coarseness: '',
    group: '',
  },
  gravel: {
    prices: [{ region: 'Все', value: '', id: uuid(), error: false }],
    volume: '',
    description: '',
    name: '',
    sparseness: '',
    frostResistance: '',
    fraction: '',
    mark: '',
    bulkCoefficient: '',
    photo: [],
    passport: [],
  },
};
export const optionsKindOfMaterial: ITypeKindMaterialOptions = {
  rubble: [
    { value: undefined, label: 'Все' },
    { value: 'Гранитный', label: 'Гранитный' },
    { value: 'Гравийный', label: 'Гравийный' },
    { value: 'Известняковый', label: 'Известняковый' },
  ],
  'secondary-crushed-stone': [
    { value: undefined, label: 'Все' },
    { value: 'Отсев дробления', label: 'Отсев дробления' },
    { value: 'Бетонный', label: 'Бетонный' },
    { value: 'Кирпичный бой', label: 'Кирпичный бой' },
  ],
  sand: [
    { value: undefined, label: 'Все' },
    { value: 'Пескогрунт', label: 'Пескогрунт' },
    { value: 'Карьерный', label: 'Карьерный' },
    { value: 'Сеяный', label: 'Сеяный' },
    { value: 'Мытый', label: 'Мытый' },
    { value: 'Намывной', label: 'Намывной' },
    { value: 'Речной', label: 'Речной' },
    { value: 'Кварцевый', label: 'Кварцевый' },
  ],
  psc: [{ value: undefined, label: 'Все' }],
  asg: [
    { value: undefined, label: 'Все' },
    { value: 'Натуральная', label: 'Натуральная' },
    { value: 'Обогащенная', label: 'Обогащенная' },
  ],
  gravel: [{ value: undefined, label: 'Все' }],
};
export const arrayCompare = [
  '8-11,2 мм',
  '11,2-16 мм',
  '16-22,4 мм',
  '16-31,5 мм',
  '22,4-31,5 мм',
  '31,5-45 мм',
  '31,5-63 мм',
  'До 0,7 мм',
  '0,7-1,0 мм',
  '1,0-1,5 мм',
  '1,5-2,0 мм',
  '2,0-2,5 мм',
  '2,5-3,0 мм',
  '3,0-3,5 мм',
  'Свыше 3,5 мм',
];
export const optionsInputsOfMaterial: ITypeMaterialsOptionsInputs = {
  rubble: {
    kind: [
      { value: 'Гранитный', label: 'Гранитный' },
      { value: 'Гравийный', label: 'Гравийный' },
      { value: 'Известняковый', label: 'Известняковый' },
    ],
    fraction: [
      { value: '0-4 мм', label: '0-4 мм' },
      { value: '3-10 мм', label: '3-10 мм' },
      { value: '4-8 мм', label: '4-8 мм' },
      { value: '4-16 мм', label: '4-16 мм' },
      { value: '8-11,2 мм', label: '8-11,2 мм' },
      { value: '8-16 мм', label: '8-16 мм' },
      { value: '11,2-16 мм', label: '11,2-16 мм' },
      { value: '16-22,4 мм', label: '16-22,4 мм' },
      { value: '16-31,5 мм', label: '16-31,5 мм' },
      { value: '22,4-31,5 мм', label: '22,4-31,5 мм' },
      { value: '31,5-45 мм', label: '31,5-45 мм' },
      { value: '25-60 мм', label: '25-60 мм' },
      { value: '31,5-63 мм', label: '31,5-63 мм' },
      { value: '0-100 мм', label: '0-100 мм' },
      { value: '5-10 мм', label: '5-10 мм' },
      { value: '5-20 мм', label: '5-20 мм' },
      { value: '20-40 мм', label: '20-40 мм' },
      { value: '40-70 мм', label: '40-70 мм' },
      { value: '70-100 мм', label: '70-100 мм' },
    ],
    mark: [
      { value: 'М100', label: 'М100' },
      { value: 'М200', label: 'М200' },
      { value: 'М300', label: 'М300' },
      { value: 'М400', label: 'М400' },
      { value: 'М500', label: 'М500' },
      { value: 'М600', label: 'М600' },
      { value: 'М700', label: 'М700' },
      { value: 'М800', label: 'М800' },
      { value: 'М900', label: 'М900' },
      { value: 'М1000', label: 'М1000' },
      { value: 'М1100', label: 'М1100' },
      { value: 'М1200', label: 'М1200' },
      { value: 'М1300', label: 'М1300' },
      { value: 'М1400', label: 'М1400' },
    ],
    frostResistance: [
      { value: 'F-15', label: 'F-15' },
      { value: 'F-25', label: 'F-25' },
      { value: 'F-50', label: 'F-50' },
      { value: 'F-100', label: 'F-100' },
      { value: 'F-150', label: 'F-150' },
      { value: 'F-200', label: 'F-200' },
      { value: 'F-300', label: 'F-300' },
      { value: 'F-400', label: 'F-400' },
    ],
  },
  'secondary-crushed-stone': {
    kind: [
      { value: 'Отсев дробления', label: 'Отсев дробления' },
      { value: 'Бетонный', label: 'Бетонный' },
      { value: 'Кирпичный бой', label: 'Кирпичный бой' },
    ],
    fractionScreening: [
      { value: '0-10 мм', label: '0-10 мм' },
      { value: '0-20 мм', label: '0-20 мм' },
      { value: '0-40 мм', label: '0-40 мм' },
      { value: '0-70 мм', label: '0-70 мм' },
      { value: '0-100 мм', label: '0-100 мм' },
    ],
    fractionConcrete: [
      { value: '5-20 мм', label: '5-20 мм' },
      { value: '3-10 мм', label: '3-10 мм' },
      { value: '20-40 мм', label: '20-40 мм' },
      { value: '40-70 мм', label: '40-70 мм' },
    ],
    fractionBrick: [{ value: '0-100 мм', label: '0-100 мм' }],
  },
  sand: {
    kind: [
      { value: 'Пескогрунт', label: 'Пескогрунт' },
      { value: 'Карьерный', label: 'Карьерный' },
      { value: 'Сеяный', label: 'Сеяный' },
      { value: 'Мытый', label: 'Мытый' },
      { value: 'Намывной', label: 'Намывной' },
      { value: 'Речной', label: 'Речной' },
      { value: 'Кварцевый', label: 'Кварцевый' },
    ],
    coarseness: [
      { value: 'До 0,7 мм', label: 'До 0,7 мм' },
      { value: '0,7-1,0 мм', label: '0,7-1,0 мм' },
      { value: '1,0-1,5 мм', label: '1,0-1,5 мм' },
      { value: '1,5-2,0 мм', label: '1,5-2,0 мм' },
      { value: '2,0-2,5 мм', label: '2,0-2,5 мм' },
      { value: '2,5-3,0 мм', label: '2,5-3,0 мм' },
      { value: '3,0-3,5 мм', label: '3,0-3,5 мм' },
      { value: 'Свыше 3,5 мм', label: 'Свыше 3,5 мм' },
    ],
    class: [
      {
        value: 'Класс I',
        label: 'Класс I',
      },
      {
        value: 'Класс II',
        label: 'Класс II',
      },
    ],
    group: [
      { value: 'Очень тонкий', label: 'Очень тонкий' },
      { value: 'Тонкий', label: 'Тонкий' },
      { value: 'Очень мелкий', label: 'Очень мелкий' },
      { value: 'Мелкий', label: 'Мелкий' },
      { value: 'Средний', label: 'Средний' },
      { value: 'Крупный', label: 'Крупный' },
      { value: 'Повышеной крупности', label: 'Повышеной крупности' },
      { value: 'Очень крупный', label: 'Очень крупный' },
    ],
  },
  psc: {
    fraction: [
      { value: 'C1', label: 'C1' },
      { value: 'C2', label: 'C2' },
      { value: 'C3', label: 'C3' },
      { value: 'C4', label: 'C4' },
      { value: 'C5', label: 'C5' },
      { value: 'C6', label: 'C6' },
      { value: 'C7', label: 'C7' },
      { value: 'C8', label: 'C8' },
      { value: 'C9', label: 'C9' },
      { value: 'C10', label: 'C10' },
      { value: 'C11', label: 'C11' },
    ],
    type: [
      { value: 'Известняковый', label: 'Известняковый' },
      { value: 'Гранитный', label: 'Гранитный' },
      { value: 'Гравийный', label: 'Гравийный' },
    ],
    frostResistance: [
      { value: 'F-15', label: 'F-15' },
      { value: 'F-25', label: 'F-25' },
      { value: 'F-50', label: 'F-50' },
      { value: 'F-100', label: 'F-100' },
      { value: 'F-150', label: 'F-150' },
      { value: 'F-200', label: 'F-200' },
      { value: 'F-300', label: 'F-300' },
      { value: 'F-400', label: 'F-400' },
    ],
    mark: [
      { value: 'М100', label: 'М100' },
      { value: 'М200', label: 'М200' },
      { value: 'М300', label: 'М300' },
      { value: 'М400', label: 'М400' },
      { value: 'М500', label: 'М500' },
      { value: 'М600', label: 'М600' },
      { value: 'М700', label: 'М700' },
      { value: 'М800', label: 'М800' },
      { value: 'М900', label: 'М900' },
      { value: 'М1000', label: 'М1000' },
      { value: 'М1100', label: 'М1100' },
      { value: 'М1200', label: 'М1200' },
      { value: 'М1300', label: 'М1300' },
      { value: 'М1400', label: 'М1400' },
    ],
  },
  asg: {
    kind: [
      { value: 'Натуральная', label: 'Натуральная' },
      { value: 'Обогащенная', label: 'Обогащенная' },
    ],
    fraction: [
      { value: '0-10 мм', label: '0-10 мм' },
      { value: '5-20 мм', label: '5-20 мм' },
      { value: '10-20 мм', label: '10-20 мм' },
      { value: '20-40 мм', label: '20-40 мм' },
      { value: '40-70 мм', label: '40-70 мм' },
    ],
    mark: [
      { value: 'М800', label: 'М800' },
      { value: 'М900', label: 'М900' },
      { value: 'М1000', label: 'М1000' },
      { value: 'М1100', label: 'М1100' },
      { value: 'М1200', label: 'М1200' },
      { value: 'М1300', label: 'М1300' },
      { value: 'М1400', label: 'М1400' },
    ],
    frostResistance: [
      { value: 'F-15', label: 'F-15' },
      { value: 'F-25', label: 'F-25' },
      { value: 'F-50', label: 'F-50' },
      { value: 'F-100', label: 'F-100' },
      { value: 'F-150', label: 'F-150' },
      { value: 'F-200', label: 'F-200' },
      { value: 'F-300', label: 'F-300' },
      { value: 'F-400', label: 'F-400' },
    ],
    category: [
      { value: 'I (процент гравия от 15 до 25%)', label: 'I (процент гравия от 15 до 25%)' },
      { value: 'II (процент гравия от 25 до 35%)', label: 'II (процент гравия от 25 до 35%)' },
      { value: 'III (процент гравия от 35 до 50%)', label: 'III (процент гравия от 35 до 50%)' },
      { value: 'IV (процент гравия от 50 до 65%)', label: 'IV (процент гравия от 50 до 65%)' },
      { value: 'V (процент гравия от 65 до 75%)', label: 'V (процент гравия от 65 до 75%)' },
    ],
    group: [
      { value: 'Очень тонкий', label: 'Очень тонкий' },
      { value: 'Тонкий', label: 'Тонкий' },
      { value: 'Очень мелкий', label: 'Очень мелкий' },
      { value: 'Мелкий', label: 'Мелкий' },
      { value: 'Средний', label: 'Средний' },
      { value: 'Крупный', label: 'Крупный' },
      { value: 'Повышеной крупности', label: 'Повышеной крупности' },
      { value: 'Очень крупный', label: 'Очень крупный' },
    ],
    coarseness: [
      { value: 'До 0,7 мм', label: 'До 0,7 мм' },
      { value: '0,7-1,0 мм', label: '0,7-1,0 мм' },
      { value: '1,0-1,5 мм', label: '1,0-1,5 мм' },
      { value: '1,5-2,0 мм', label: '1,5-2,0 мм' },
      { value: '2,0-2,5 мм', label: '2,0-2,5 мм' },
      { value: '2,5-3,0 мм', label: '2,5-3,0 мм' },
      { value: '3,0-3,5 мм', label: '3,0-3,5 мм' },
      { value: 'Свыше 3,5 мм', label: 'Свыше 3,5 мм' },
    ],
  },
};
export const coarsenessByGroup = {
  'Очень тонкий': 'До 0,7 мм',
  Тонкий: '0,7-1,0 мм',
  'Очень мелкий': '1,0-1,5 мм',
  Мелкий: '1,5-2,0 мм',
  Средний: '2,0-2,5 мм',
  Крупный: '2,5-3,0 мм',
  'Повышеной крупности': '3,0-3,5 мм',
  'Очень крупный': 'Свыше 3,5 мм',
};
export const optionsAreaOfPrice: IOptions[] = [
  { value: 'Москва', label: 'Москва' },
  { value: 'Московская область', label: 'Московская область' },
];
export const optionsUnitOfMeasurement: IOptions[] = [
  { value: 'т', label: 'т' },
  { value: 'м³', label: 'м³' },
];
const chooseOptionFractionSecondaryCrushedStone = {
  'Отсев дробления': optionsInputsOfMaterial['secondary-crushed-stone'].fractionScreening,
  'Кирпичный бой': optionsInputsOfMaterial['secondary-crushed-stone'].fractionBrick,
  Бетонный: optionsInputsOfMaterial['secondary-crushed-stone'].fractionConcrete,
};
export const convertValueFromObj = value => {
  return typeof value === 'object' ? value.label : value || '';
};
export const convertValueFromObjForUnitMeasurement = value => {
  return typeof value === 'string' && value !== '' ? { value: value, label: value } : value;
};

export const arrayInputsOfMaterial = (
  valueOfInputsOfMaterial: ITypeInputsOfMaterial,
  setValueOfInputsOfMaterial: React.Dispatch<React.SetStateAction<ITypeInputsOfMaterial>>,
): ITypeOfArrayInputsOfMaterial => {
  return {
    rubble: [
      {
        title: '1. Вид материала',
        input: 'select',
        options: optionsInputsOfMaterial.rubble.kind,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, rubble: { ...prevState.rubble, kind: e } })),
        value: valueOfInputsOfMaterial.rubble.kind,
      },
      {
        title: '2. Фракция материала',
        input: 'select',
        options: optionsInputsOfMaterial.rubble.fraction,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, rubble: { ...prevState.rubble, fraction: e } })),
        value: valueOfInputsOfMaterial.rubble.fraction,
      },
      {
        title: '3. Прочность материала',
        input: 'select',
        options: optionsInputsOfMaterial.rubble.mark,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, rubble: { ...prevState.rubble, mark: e } })),
        value: valueOfInputsOfMaterial.rubble.mark,
      },
      {
        title: '4. Морозостойкость материала',
        input: 'select',
        options: optionsInputsOfMaterial.rubble.frostResistance,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({
            ...prevState,
            rubble: { ...prevState.rubble, frostResistance: e },
          })),
        value: valueOfInputsOfMaterial.rubble.frostResistance,
      },
      {
        title: '5. Насыпная плотность, т/м3',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForNumberWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              rubble: { ...prevState.rubble, bulkCoefficient: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.rubble.bulkCoefficient,
      },
      {
        title: '6. Лещадность, %',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              rubble: { ...prevState.rubble, sparseness: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.rubble.sparseness,
      },
      {
        title: '7. Содержание ПГЧ, %',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              rubble: { ...prevState.rubble, hghContent: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.rubble.hghContent,
      },
    ],
    'secondary-crushed-stone': [
      {
        title: '1. Вид материала',
        input: 'select',
        options: optionsInputsOfMaterial['secondary-crushed-stone'].kind,
        disabled: false,
        error: false,
        required: true,
        onChange: (e: string) =>
          setValueOfInputsOfMaterial(prevState => ({
            ...prevState,
            'secondary-crushed-stone': { ...prevState['secondary-crushed-stone'], kind: e },
          })),
        value: valueOfInputsOfMaterial['secondary-crushed-stone'].kind,
      },
      {
        title: '2. Фракция материала',
        input: 'select',
        options:
          typeof valueOfInputsOfMaterial['secondary-crushed-stone'].kind === 'object'
            ? chooseOptionFractionSecondaryCrushedStone[valueOfInputsOfMaterial['secondary-crushed-stone'].kind.value]
            : chooseOptionFractionSecondaryCrushedStone[valueOfInputsOfMaterial['secondary-crushed-stone'].kind],
        disabled: !valueOfInputsOfMaterial['secondary-crushed-stone'].kind,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({
            ...prevState,
            'secondary-crushed-stone': { ...prevState['secondary-crushed-stone'], fraction: e },
          })),
        value: valueOfInputsOfMaterial['secondary-crushed-stone'].fraction,
      },
      {
        title: '3. Насыпная плотность, т/м3',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForNumberWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              'secondary-crushed-stone': { ...prevState['secondary-crushed-stone'], bulkCoefficient: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial['secondary-crushed-stone'].bulkCoefficient,
      },
    ],
    sand: [
      {
        title: '1. Вид материала',
        input: 'select',
        options: optionsInputsOfMaterial.sand.kind,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, sand: { ...prevState.sand, kind: e } })),
        value: valueOfInputsOfMaterial.sand.kind,
      },
      {
        title: '2. Класс песка',
        input: 'select',
        options: optionsInputsOfMaterial.sand.class,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, sand: { ...prevState.sand, class: e } })),
        value: valueOfInputsOfMaterial.sand.class,
      },
      {
        title: '3. Коэффициент фильтрации',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: false,
        onChange: e => {
          if (regForNumberWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              sand: { ...prevState.sand, filtrationCoefficient: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.sand.filtrationCoefficient,
      },
      {
        title: '4. Группа песка',
        input: 'select',
        options: optionsInputsOfMaterial.sand.group,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({
            ...prevState,
            sand: { ...prevState.sand, group: e, coarseness: coarsenessByGroup[convertValueFromObj(e)] },
          })),
        value: valueOfInputsOfMaterial.sand.group,
      },
      {
        title: '5. Модуль крупности',
        input: 'select',
        options: optionsInputsOfMaterial.sand.coarseness,
        disabled: true,
        error: false,
        required: true,
        onChange: e => {
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, sand: { ...prevState.sand, coarseness: e } }));
        },
        value: coarsenessByGroup[convertValueFromObj(valueOfInputsOfMaterial.sand.group)],
      },
      {
        title: '6. Насыпная плотность, т/м3',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForNumberWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              sand: { ...prevState.sand, bulkCoefficient: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.sand.bulkCoefficient,
      },
      {
        title: '7. Содержание ПГЧ, %',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              sand: { ...prevState.sand, hghContent: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.sand.hghContent,
      },
    ],
    psc: [
      {
        title: '1. Вид материала',
        input: 'select',
        options: optionsInputsOfMaterial.psc.fraction,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, psc: { ...prevState.psc, fraction: e } })),
        value: valueOfInputsOfMaterial.psc.fraction,
      },
      {
        title: '2. Типы щебня',
        input: 'select',
        options: optionsInputsOfMaterial.psc.type,
        disabled: false,
        error: false,
        required: true,
        onChange: e => setValueOfInputsOfMaterial(prevState => ({ ...prevState, psc: { ...prevState.psc, type: e } })),
        value: valueOfInputsOfMaterial.psc.type,
      },
      {
        title: '3. Морозостойкость материала',
        input: 'select',
        options: optionsInputsOfMaterial.psc.frostResistance,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, psc: { ...prevState.psc, frostResistance: e } })),
        value: valueOfInputsOfMaterial.psc.frostResistance,
      },
      {
        title: '4. Прочность материала',
        input: 'select',
        options: optionsInputsOfMaterial.psc.mark,
        disabled: false,
        error: false,
        required: true,
        onChange: e => setValueOfInputsOfMaterial(prevState => ({ ...prevState, psc: { ...prevState.psc, mark: e } })),
        value: valueOfInputsOfMaterial.psc.mark,
      },
      {
        title: '5. Насыпная плотность, т/м3',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForNumberWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({ ...prevState, psc: { ...prevState.psc, bulkCoefficient: e } }));
          }
        },
        value: valueOfInputsOfMaterial.psc.bulkCoefficient,
      },
      {
        title: '6. Лещадность, %',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({ ...prevState, psc: { ...prevState.psc, sparseness: e } }));
          }
        },
        value: valueOfInputsOfMaterial.psc.sparseness,
      },
      {
        title: '7. Содержание ПГЧ, %',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              psc: { ...prevState.psc, hghContent: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.psc.hghContent,
      },
    ],
    asg: [
      {
        title: '1. Вид материала',
        input: 'select',
        options: optionsInputsOfMaterial.asg.kind,
        disabled: false,
        error: false,
        required: true,
        onChange: e => setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, kind: e } })),
        value: valueOfInputsOfMaterial.asg.kind,
      },
      {
        title: '2. Фракция материала',
        input: 'select',
        options: optionsInputsOfMaterial.asg.fraction,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, fraction: e } })),
        value: valueOfInputsOfMaterial.asg.fraction,
      },
      {
        title: '3. Прочность материала',
        input: 'select',
        options: optionsInputsOfMaterial.asg.mark,
        disabled: false,
        error: false,
        required: true,
        onChange: e => setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, mark: e } })),
        value: valueOfInputsOfMaterial.asg.mark,
      },
      {
        title: '4. Морозостойкость материала',
        input: 'select',
        options: optionsInputsOfMaterial.asg.frostResistance,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, frostResistance: e } })),
        value: valueOfInputsOfMaterial.asg.frostResistance,
      },
      {
        title: '5. Категория материала',
        input: 'select',
        options: optionsInputsOfMaterial.asg.category,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, category: e } })),
        value: valueOfInputsOfMaterial.asg.category,
      },
      {
        title: '6. Насыпная плотность, т/м3',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForNumberWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, bulkCoefficient: e } }));
          }
        },
        value: valueOfInputsOfMaterial.asg.bulkCoefficient,
      },
      {
        title: '7. % Песка',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, sandPercent: e } }));
          }
        },
        value: valueOfInputsOfMaterial.asg.sandPercent,
      },
      {
        title: '8. Группа песка',
        input: 'select',
        options: optionsInputsOfMaterial.asg.group,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({
            ...prevState,
            asg: { ...prevState.asg, group: e, coarseness: coarsenessByGroup[convertValueFromObj(e)] },
          })),
        value: valueOfInputsOfMaterial.asg.group,
      },
      {
        title: '9. Модуль крупности песка',
        input: 'select',
        options: optionsInputsOfMaterial.asg.coarseness,
        disabled: true,
        error: false,
        required: true,
        onChange: e => {
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, coarseness: e } }));
        },
        value: coarsenessByGroup[convertValueFromObj(valueOfInputsOfMaterial.asg.group)],
      },
      {
        title: '10. % Гравия',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({ ...prevState, asg: { ...prevState.asg, gravelPercent: e } }));
          }
        },
        value: valueOfInputsOfMaterial.asg.gravelPercent,
      },
      {
        title: '11. Содержание ПГЧ, %',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              asg: { ...prevState.asg, hghContent: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.asg.hghContent,
      },
    ],
    gravel: [
      {
        title: '1. Фракция материала',
        input: 'select',
        options: optionsInputsOfMaterial.rubble.fraction,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, gravel: { ...prevState.gravel, fraction: e } })),
        value: valueOfInputsOfMaterial.gravel.fraction,
      },
      {
        title: '2. Прочность материала',
        input: 'select',
        options: optionsInputsOfMaterial.rubble.mark,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({ ...prevState, gravel: { ...prevState.gravel, mark: e } })),
        value: valueOfInputsOfMaterial.gravel.mark,
      },
      {
        title: '3. Морозостойкость материала',
        input: 'select',
        options: optionsInputsOfMaterial.rubble.frostResistance,
        disabled: false,
        error: false,
        required: true,
        onChange: e =>
          setValueOfInputsOfMaterial(prevState => ({
            ...prevState,
            gravel: { ...prevState.gravel, frostResistance: e },
          })),
        value: valueOfInputsOfMaterial.gravel.frostResistance,
      },
      {
        title: '4. Насыпная плотность, т/м3',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForNumberWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              gravel: { ...prevState.gravel, bulkCoefficient: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.gravel.bulkCoefficient,
      },
      {
        title: '5. Лещадность, %',
        input: '',
        options: '',
        disabled: false,
        error: false,
        required: true,
        onChange: e => {
          if (regForFlakinessWithComma.test(e) || e === '') {
            setValueOfInputsOfMaterial(prevState => ({
              ...prevState,
              gravel: { ...prevState.gravel, sparseness: e },
            }));
          }
        },
        value: valueOfInputsOfMaterial.gravel.sparseness,
      },
    ],
  };
};
export const initStateDataCardResponse: dataCardResponseType[] = [
  {
    id: 0,
    title: '',
    link: '',
    description: '',
    isEnabledLink: false,
    files: [
      {
        id: 0,
        communicationCategory: '',
        fileBelongsId: 0,
        name: '',
        category: '',
        mimeType: '',
        location: '',
      },
      {
        id: 0,
        communicationCategory: '',
        fileBelongsId: 0,
        name: '',
        category: '',
        mimeType: '',
        location: '',
      },
    ],
    quarry: {
      address: {
        address: '',
        latitude: 0,
        longitude: 0,
      },
      id: 0,
      name: '',
      quarryType: '',
    },
  },
];
