import React, { Dispatch, SetStateAction } from 'react';
import styles from './AdminTariffsFilter.module.scss';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import { contractorRoleOptions, periodOptions } from '../../constants';
import { IFilterTariffs, IMaterialOptions, IOrganizationOptions, IPeriodOptions } from '../../types';

interface IProps {
  filter: IFilterTariffs;
  setFilter: Dispatch<SetStateAction<IFilterTariffs>>;
  organizationOptions: IOrganizationOptions[];
  materialOptions: IMaterialOptions[];
  periodOptions: IPeriodOptions[];
}

export const AdminTariffsFilter = ({
  filter,
  setFilter,
  organizationOptions,
  materialOptions,
  periodOptions,
}: IProps) => {
  return (
    <div className={styles.filtersContainer}>
      <h1 className={styles.filterHeader}>Фильтры</h1>
      <div className={styles.filtersContainer}>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Организатор перевозки</h2>
          <SelectLk
            options={organizationOptions.map(item => {
              return { value: item.title, label: item.title };
            })}
            placeholder="Выберите объект из списка"
            value={filter?.organizator?.title}
            setValue={value =>
              setFilter(prevState => ({
                ...prevState,
                organizator: organizationOptions.find(item => item.title === value),
              }))
            }
          />
        </div>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Роль контрагента</h2>
          <SelectLk
            options={contractorRoleOptions}
            placeholder="Выберите из списка"
            value={filter.typeOfContractor}
            setValue={value => setFilter(prevState => ({ ...prevState, typeOfContractor: value }))}
          />
        </div>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Срок действия тарифа</h2>
          <SelectLk
            options={periodOptions.map(item => {
              return { value: item.title, label: item.title };
            })}
            placeholder="Выберите из списка"
            value={filter?.validityPeriod?.title}
            setValue={value =>
              setFilter(prevState => ({
                ...prevState,
                validityPeriod: periodOptions.find(item => item.title === value),
              }))
            }
          />
        </div>
        <div className={styles.filterWithHeader}>
          <h2 className={styles.filterSubHeader}>Материал</h2>
          <SelectLk
            options={materialOptions.map(item => {
              return { value: item.title, label: item.title };
            })}
            placeholder="Выберите материал из списка"
            value={filter?.material?.title}
            setValue={value =>
              setFilter(prevState => ({
                ...prevState,
                material: materialOptions.find(item => item.title === value),
              }))
            }
          />
        </div>
      </div>
    </div>
  );
};
