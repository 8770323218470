import React, { useRef, useState } from 'react';
import styles from './ModalQuarryBlocking.module.scss';
import cx from 'classnames';
import { ButtonSimple } from '../../../../../components/newUI/ButtonSimple/ButtonSimple';
import { EditSvg } from '../../../../../components/UI/svg-icon/EditSvg';
import { CloseSvg } from '../../../../../components/UI/svg-icon/CloseSvg';
import CustomDatePicker from '../../../../../components/newUI/CustomDatePicker/CustomDatePicker';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import ButtonFilled from '../../../../../components/newUI/ButtonFilled/ButtonFilled';
import useClickOutside from '../../../../../CustomHooks/useClickOutside';
import ButtonNotFilled from '../../../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { IQuarries } from '../../types';
import { fetchPost } from '../../../../../common/proxy-1C-fetch-auth';
import { toastError } from '../../../../../common/toastError.helper';
import { convertTimeAndDateToBackend } from '../../utils';

const datePlaceholder = dayjs(new Date()).format('DD.MM.YYYY').toString();
interface IProps {
  text: string;
  data: IQuarries;
}
const ModalQuarryBlocking = ({ text, data }: IProps) => {
  const [inputs, setInputs] = useState({
    time: '',
    date: '',
  });
  const [openTimePicker, setOpenTimePicker] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const dateFrom = new Date(text);
  const dateTo = new Date(data?.blockTo);
  const clickRef = useRef();
  useClickOutside(clickRef, setOpenModal);

  const convertTime = (time: Date) => {
    return dayjs(new Date(time.setHours(time.getHours() - 3))).format('DD.MM.YYYY HH:mm');
  };
  const changeBlockDateQuarries = async () => {
    try {
      const response = await fetchPost(`/admin/quarries/blocked/${data?.id}`, 'PATCH', {
        blockTo: convertTimeAndDateToBackend(inputs.date, inputs.time),
      });
      if (toastError(response)) return;
    } catch (e) {
      console.log(e);
    } finally {
      setOpenModal(false);
    }
  };
  const customTimePickerButton = () => {
    return <ButtonFilled text="Готово" onClick={() => setOpenTimePicker(false)} width="100%" sizeText="small" />;
  };
  return (
    <div>
      <div className={styles.columnAndBorder}>
        <div className={styles.datesContainer} style={{ width: '192px' }}>
          <div className={cx(styles.columnContainer, styles.twoRows)}>
            <p className={styles.tableText}>{convertTime(dateFrom) || 'Нет данных'}</p>
            <p className={styles.tableText}>{convertTime(dateTo) || 'Нет данных'}</p>
          </div>
          <ButtonSimple
            svg={<EditSvg />}
            onClick={e => {
              e.stopPropagation();
              setOpenModal(true);
            }}
          />
        </div>
        <div className={styles.border}></div>
      </div>
      {openModal && (
        <div ref={clickRef} onClick={event => event.stopPropagation()} className={styles.modalContainer}>
          <div className={styles.headerModal}>
            <p className={styles.modalTitle}>Конец блокировки (Дата/время) *</p>
            <div style={{ cursor: 'pointer' }} onClick={() => setOpenModal(false)}>
              <CloseSvg />
            </div>
          </div>
          <div className={styles.datetime}>
            <CustomDatePicker
              value={inputs?.date ? dayjs(inputs?.date, 'DD.MM.YYYY') : null}
              setValue={date => setInputs(prevState => ({ ...prevState, date: date?.format('DD.MM.YYYY').toString() }))}
              width="150px"
              height="48px"
              paddingRight="16px"
              paddingLeft="4px"
              placeholder={datePlaceholder}
            />
            <TimePicker
              value={inputs?.time ? dayjs(inputs?.time, 'HH:mm') : null}
              showNow={false}
              needConfirm={false}
              placeholder={'00:00'}
              format={'HH:mm'}
              onChange={(time, timeString) => setInputs(prevState => ({ ...prevState, time: timeString as string }))}
              open={openTimePicker}
              onClick={() => setOpenTimePicker(true)}
              renderExtraFooter={() => customTimePickerButton()}
              height="48px"
              style={{ padding: '4px 16px 4px 4px' }}
            />
          </div>
          <div className={styles.btnContainer}>
            <ButtonFilled
              text={'Сохранить'}
              width={'100%'}
              onClick={() => changeBlockDateQuarries()}
              disabled={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalQuarryBlocking;
