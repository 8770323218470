import React, { useContext } from 'react';
import { Container } from '../Container';
import { Context } from '../../context/context';
import { ButtonExit } from '../UI/buttons/ButtonExit';
import { Link, NavLink } from 'react-router-dom';
import { logoType } from '../../common/logo.type';
import { logout } from '../../common/logout.mapping';
import Notices from '../../layouts/Notices/Notices';
import styles from './Header.module.scss';
import { ButtonSimple } from '../newUI/ButtonSimple/ButtonSimple';
import { backToRoot } from 'src/common/user/backToRoot.helper';
export const Header = () => {
  const { user, setUser, setStartTour } = useContext(Context);

  return (
    <div className="Header">
      <Container>
        <div className="Header__wrapper">
          <Link to="/" className="Header__logo">
            {Object.entries(logoType).map(
              ([role, logo]) => role === (user.type === 'driver' ? 'driver' : user.companyType || 'admin') && logo.desc,
            )}
          </Link>
          <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
            <div className={styles.textStartTour}>
              {user?.companyType && !user?.root && (
                <ButtonSimple text={'Запуск обучения'} onClick={() => setStartTour(true)} smallDesktopText={true} />
              )}
              {user?.companyType && user?.root && (
                <ButtonSimple
                  text={'Вернуться в режим администратора'}
                  onClick={() => backToRoot(user, setUser)}
                  smallDesktopText={true}
                />
              )}
            </div>
            {user.type !== 'buyer' && (
              <div style={{ marginRight: '32px' }}>
                <Notices />
              </div>
            )}
            <NavLink to="/">
              <ButtonExit isText={true} onClick={() => logout(user, setUser)} />
            </NavLink>
          </div>
        </div>
      </Container>
    </div>
  );
};
