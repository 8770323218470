import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction, Slice, UnknownAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { addEmployee, deleteEmployee, editEmployee, getEmployees } from './apiEmployees';
import {
  IEmployees,
  IRequestAddEmployeeOptions,
  IRequestEditEmployeesOptions,
  IRequestGetEmployeesOptions,
} from './type';

export const getAllEmployees = createAsyncThunk(
  'employees/getAllEmployees',
  async (data: IRequestGetEmployeesOptions) => {
    const response = await getEmployees(data);
    return response.data;
  },
);

export const editCurrentEmployee = createAsyncThunk(
  'employees/editCurrentEmployee',
  async (data: IRequestEditEmployeesOptions) => {
    const response = await editEmployee(data);
    return response.data;
  },
);

export const addCurrentEmployee = createAsyncThunk(
  'employees/addCurrentEmployee',
  async (data: IRequestAddEmployeeOptions) => {
    const response = await addEmployee(data);
    return response.data;
  },
);

export const deleteCurrentEmployee = createAsyncThunk('employees/deleteCurrentEmployee', async (id: number) => {
  const response = await deleteEmployee(id);
  return response.data;
});

export const createEmployee = createAsyncThunk('employees/addEmployee', async () => {});

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
function isPendingAction(action: UnknownAction): action is PendingAction {
  return typeof action.type === 'string' && action.type.endsWith('/pending');
}
function isRejectedAction(action: UnknownAction): action is RejectedAction {
  return typeof action.type === 'string' && action.type.endsWith('/rejected');
}

export interface employeesState {
  employees: IEmployees[];
  employeesTotalCount: number;
  loading: boolean;
  editEmployee: IEmployees;
  errorMessage: string[];
}

const initialState: employeesState = {
  employees: [],
  loading: false,
  employeesTotalCount: null,
  editEmployee: null,
  errorMessage: [],
};

const initEditEmployee: IEmployees = {
  name: '',
  surname: '',
  patronymic: '',
  phone: '',
  profile: { email: '' },
  type: '',
};

export const employeesSlice: Slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    addEditEmployee: (state, action: PayloadAction<number>) => {
      state.editEmployee = action?.payload
        ? state.employees?.find(employee => employee.id === action?.payload)
        : initEditEmployee;
    },
    editEmployeeParameters: (state, action: PayloadAction<IRequestEditEmployeesOptions>) => {
      const parameters = action.payload?.parameters;
      if (state.editEmployee) {
        Object.assign(state.editEmployee, parameters);
        if (parameters.email) {
          state.editEmployee.profile.email = parameters.email;
        }
      }
    },
    addEmployeeParameters: (state, action: PayloadAction<IRequestAddEmployeeOptions>) => {
      const parameters = action.payload;
      if (state.editEmployee) {
        Object.assign(state.editEmployee, parameters);
        if (parameters.email) {
          state.editEmployee.profile.email = parameters.email;
        }
      }
    },
    clearEditEmployee: state => {
      state.editEmployee = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllEmployees.fulfilled, (state, action) => {
      const correctedEmployees = action.payload.response?.rows?.map(item => {
        return {
          ...item,
          fullName: `${item?.surname} ${item?.name} ${item?.patronymic}`,
        };
      });
      state.employees = action.meta.arg.offset > 0 ? [...state.employees, ...correctedEmployees] : correctedEmployees;
      state.employeesTotalCount = action.payload?.response?.count;
      state.loading = false;
    });

    builder.addCase(editCurrentEmployee.fulfilled, (state, action) => {
      const index = action?.payload?.response?.id;
      const editEmployee = state.editEmployee;
      state.employees = state.employees.map(employee =>
        employee.id === index
          ? {
              ...employee,
              ...editEmployee,
              fullName: `${editEmployee?.surname} ${editEmployee?.name} ${editEmployee?.patronymic}`,
            }
          : employee,
      );
      state.editEmployee = null;
      state.loading = false;
    });

    builder.addCase(addCurrentEmployee.fulfilled, (state, action) => {
      const index = action?.payload?.response?.id;
      if (index !== -1) {
        const editEmployee = state.editEmployee;
        state.employees.unshift({
          id: index,
          ...editEmployee,
          fullName: `${editEmployee?.surname} ${editEmployee?.name} ${editEmployee?.patronymic}`,
        });
      }
      state.editEmployee = null;
      state.loading = false;
    });

    builder.addCase(deleteCurrentEmployee.fulfilled, (state, action) => {
      state.employees = state.employees.filter(employee => employee?.id !== action?.payload?.response?.id);
      state.employeesTotalCount -= 1;
      state.loading = false;
    });

    builder.addMatcher(isPendingAction, state => {
      state.loading = true;
    });

    builder.addMatcher(isRejectedAction, state => {
      state.loading = false;
    });
  },
});

export const { addEditEmployee, editEmployeeParameters, addEmployeeParameters, clearEditEmployee } =
  employeesSlice.actions;

export const selectEmployees = (state: RootState) => state.employees.employees;
export const selectEmployeesTotalCount = (state: RootState) => state.employees.employeesTotalCount;
export const selectEmployeesLoading = (state: RootState) => state.employees.loading;
export const selectEmployeesErrorMessage = (state: RootState) => state.employees.errorMessage;
export const selectEditEmployee = (state: RootState) => state.employees.editEmployee;
