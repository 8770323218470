import React, { useContext, useRef, useState } from 'react';
import { FlightSvg } from '../UI/svg-icon/FlightSvg';
import { FlightSvgActive } from '../UI/svg-icon/FlightSvgActive';
import { DriverLicenseSvg } from '../UI/svg-icon/DriverLicenseSvg';
import { DriverLicenseSvgActive } from '../UI/svg-icon/DriverLicenseSvgActive';
import { WalletSvg } from '../UI/svg-icon/WalletSvg';
import { WalletSvgActive } from '../UI/svg-icon/WalletSvgActive';
import { DocumentSvg } from '../UI/svg-icon/DocumentSvg';
import { HelpSvg } from '../UI/svg-icon/HelpSvg';
import { HelpSvgActive } from '../UI/svg-icon/HelpSvgActive';
import { TruckSvg } from '../UI/svg-icon/TruckSvg';
import { TruckSvgActive } from '../UI/svg-icon/TruckSvgActive';
import { ProfileSvg } from '../UI/svg-icon/ProfileSvg';
import { ProfileSvgActive } from '../UI/svg-icon/ProfileSvgActive';
import { ProfileErrorSvg } from '../UI/svg-icon/ProfileErrorSvg';
import { DocumentSvgActive } from '../UI/svg-icon/DocumentSvgActive';
// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import BurgerMenuSvg from '../UI/svg-icon/BurgerMenuSvg';
import { NavLink } from 'react-router-dom';
import NewNavItemMobile from '../newUI/NewNavItem/NewNavItemMobile';
import { Context } from '../../context/context';
import { logout } from '../../common/logout.mapping';
import useClickOutside from '../../CustomHooks/useClickOutside';
import MonitorDashboardSvg from '../UI/svg-icon/MonitorDashboardSvg';
import MonitorDashboardActiveSvg from '../UI/svg-icon/MonitorDashboardActiveSvg';
import EmployeesSvg from '../UI/svg-icon/EmployeesSvg';
import EmployeesActiveSvg from '../UI/svg-icon/EmployeesActiveSvg';
import MobileTourLogo from '../UI/svg-icon/mobileTourLogo';
import IdeaSvg from '../UI/svg-icon/IdeaSvg';
import IdeaSvgActive from '../UI/svg-icon/IdeaSvgActive';
import { GsmSvg } from '../UI/svg-icon/GsmSvg';
import { GsmSvgActive } from '../UI/svg-icon/GsmSvgActive';
import { backToRoot } from '../../common/user/backToRoot.helper';
import { ButtonSimple } from '../newUI/ButtonSimple/ButtonSimple';
const NewAsideNavCarrierMobile = () => {
  const [open, setOpen] = useState(false);
  const { user, setUser, setStartTour } = useContext(Context);
  const exceptionInn = ['9721194013', '7804661645', '8602075537', '7724435827', '6141056656'];
  const fullAccess = ['admin', 'manager', 'carrier', 'accountant'];
  const navLink = [
    {
      name: 'Диспетчеризация',
      link: '/dispatching',
      type: ['admin', 'carrier', 'manager'],
      notActiveProfile: false,
      disable: true,
      svg: <MonitorDashboardSvg />,
      svgActive: <MonitorDashboardActiveSvg />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Рейсы',
      link: '/flights',
      type: ['admin', 'carrier', 'manager'],
      notActiveProfile: false,
      disable: false,
      svg: <FlightSvg />,
      svgActive: <FlightSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Водители',
      link: '/driverLicense',
      type: ['admin', 'carrier'],
      notActiveProfile: false,
      disable: true,
      svg: <DriverLicenseSvg />,
      svgActive: <DriverLicenseSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Машины',
      link: '/cars',
      type: ['admin', 'carrier'],
      notActiveProfile: false,
      disable: true,
      svg: <TruckSvg />,
      svgActive: <TruckSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Сотрудники',
      link: '/employees',
      type: ['carrier'],
      notActiveProfile: false,
      disable: true,
      svg: <EmployeesSvg />,
      svgActive: <EmployeesActiveSvg />,
      svgProfileError: null,
      errorProfile: false,
    },
    /*exceptionInn.includes(user.inn) &&*/ {
      name: 'ГСМ',
      link: '/gsm',
      type: ['carrier'],
      notActiveProfile: false,
      disable: true,
      svg: <GsmSvg />,
      svgActive: <GsmSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Документы',
      link: '/document',
      type: ['admin', 'carrier', 'accountant'],
      notActiveProfile: false,
      disable: true,
      svg: <DocumentSvg />,
      svgActive: <DocumentSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Взаиморасчеты',
      link: '/paymentProcess#Деньги',
      type: ['admin', 'carrier', 'accountant'],
      notActiveProfile: false,
      disable: true,
      svg: <WalletSvg />,
      svgActive: <WalletSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Профиль',
      link: '/profile',
      type: fullAccess,
      notActiveProfile: true,
      disable: false,
      svg: <ProfileSvg />,
      svgActive: <ProfileSvgActive />,
      svgProfileError: <ProfileErrorSvg />,
      errorProfile: true,
    },
    {
      name: 'Помощь',
      link: '/help',
      notActiveProfile: false,
      type: fullAccess,
      disable: true,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    user?.verified && {
      name: 'Beta-тестирование',
      link: '/suggestAnIdea',
      notActiveProfile: false,
      type: fullAccess,
      disable: true,
      svg: <IdeaSvg />,
      svgActive: <IdeaSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
  ];
  const clickRef = useRef();
  useClickOutside(clickRef, setOpen);
  return (
    <div>
      <div className={styles.burgerMobile}>
        <div className={styles.burgerMobileContainer}>
          <div onClick={() => setOpen(!open)} ref={clickRef}>
            <BurgerMenuSvg />
          </div>

          <div className={styles.exitContainer}>
            {user.root ? (
              <ButtonSimple onClick={() => backToRoot(user, setUser)} text={'Режим админа'} />
            ) : (
              <div
                onClick={() => {
                  setStartTour(true);
                }}
              >
                <MobileTourLogo />
              </div>
            )}
            <NavLink to="/">
              <div className={styles.exitContainer} onClick={() => logout(user, setUser)}>
                <p
                  style={{
                    marginRight: '8px',
                  }}
                >
                  Выйти
                </p>
                <svg
                  className="ButtonExit__svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 0H2C0.89 0 0 0.89 0 2V6H2V2H16V16H2V12H0V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0ZM7.08 12.58L8.5 14L13.5 9L8.5 4L7.08 5.41L9.67 8H0V10H9.67L7.08 12.58Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      {open && (
        <div className={styles.AsideBoxMobile}>
          <div className={styles.AsideBoxMobileContainer}>
            {navLink.map(({ name, type, link, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => (
              <NewNavItemMobile
                onClick={() => setOpen(false)}
                disabled={null}
                typeAccess={type}
                key={name}
                name={name}
                link={link}
                notActiveProfile={notActiveProfile}
                svg={svg}
                svgActive={svgActive}
                svgProfileError={svgProfileError}
                errorProfile={errorProfile}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewAsideNavCarrierMobile;
