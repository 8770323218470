import React, { useState, useEffect } from 'react';
import styles from './AdminTariffsPage.module.scss';
import { PageWrapper } from 'src/components/PageWrapper';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { ConfigProvider, Spin, Table } from 'antd';
import { EmptyList } from 'src/components/EmptyList';
import { usePageScroll } from 'src/CustomHooks/usePageScroll';
import useDebounce from 'src/CustomHooks/useDebounce';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import { clearObject } from 'src/common/ClearObject.helper';
import { toastError } from 'src/common/toastError.helper';
import { columnsForTableOfTariffs, initFilterTariffs, periodOptions, tariffsSortOptions } from './constants';
import { SortBox } from 'src/components/SortBox/SortBox';
import {
  contractorRolesForBack,
  IFilterTariffs,
  IMaterialOptions,
  IOrganizationOptions,
  ISelectValue,
  ITariff,
  sortForBack,
} from './types';
import FilterBox from 'src/components/newUI/FilterBox/FilterBox';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import NewPlusSvg from '../../../components/UI/svg-icon/NewPlusSvg';
import { useNavigate } from 'react-router-dom';
import { AdminTariffsFilter } from './components/AdminTariffsFilter/AdminTariffsFilter';

const limit: number = 10;

export const AdminTariffsPage = () => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const initialStateSearch = localStorage.getItem('rootSearchTariffsField') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<IFilterTariffs>(initFilterTariffs);
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  const [tariffs, setTariffs] = useState<ITariff[]>([]);
  const [sort, setSort] = useState<ISelectValue>(tariffsSortOptions[0]);
  const [openSort, setOpenSort] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [isDisabledFilterButton, setIsDisabledFilterButton] = useState<boolean>(true);
  const [organizationOptions, setOrganizationOptions] = useState<IOrganizationOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<IMaterialOptions[]>([]);
  const navigate = useNavigate();
  const isEmptyFilters = !filter.organizator && !filter.typeOfContractor && !filter.material && !filter.validityPeriod;

  const getTariffs = async (isPagination: boolean) => {
    if (isFirstRender && initialStateSearch) {
      return;
    }
    try {
      setLoading(true);
      const response = await fetchGet(
        '/admin/tariffs/list',
        clearObject({
          limit: limit,
          offset: isPagination ? offset : 0,
          type: contractorRolesForBack[filter.typeOfContractor] || undefined,
          organizationId: filter?.organizator?.id || undefined,
          tariffDate: filter.validityPeriod?.id?.toString() || undefined,
          searchString: filter.search || undefined,
          order: sortForBack[sort?.label],
        }),
      );
      setTotalCount(response?.count);
      if (toastError(response)) return;
      setTariffs(prevState => prevState.concat(response?.rows));
    } catch (e) {
      console.log(e);
    } finally {
      setIsFirstRender(false);
      setLoading(false);
    }
  };

  const getOrganizationOptions = async () => {
    const response = await fetchGet('/admin/companies/organizations', {});
    if (toastError(response)) return;
    setOrganizationOptions(response);
  };

  const getMaterialOptions = async () => {
    const response = await fetchGet('/admin/materials/types', {});
    if (toastError(response)) return;
    setMaterialOptions(response);
  };

  const clearFilters = () => {
    setFilter(initFilterTariffs);
  };

  usePageScroll(setOffset, limit);

  useEffect(() => {
    setFilter(prevState => ({
      ...prevState,
      search: initialStateSearch,
    }));
    getOrganizationOptions();
    getMaterialOptions();
  }, []);

  useEffect(() => {
    if (totalCount > offset) {
      getTariffs(true);
    }
  }, [offset]);

  useEffect(() => {
    setIsDisabledFilterButton(isEmptyFilters);
    if (tariffs.length) setTariffs([]);
    if (offset !== 0) setOffset(0);
    if (!isFirstRender) getTariffs(false);
    setTotalCount(0);
  }, [filter.material, filter.organizator, filter.typeOfContractor, filter.validityPeriod, sort]);

  useEffect(() => {
    if (tariffs.length) setTariffs([]);
    if (offset !== 0) setOffset(0);
    localStorage.setItem('rootSearchTariffsField', filter.search);
    setTotalCount(0);
    getTariffs(false);
  }, [debouncedSearchTerm]);

  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.container}>
          <div className={styles.titleAndSubtitleContainer}>
            <div className={styles.titleAndButtonContainer}>
              <TitleMain title={'Тарифы'} />
              <ButtonFilled
                onClick={() => {
                  navigate('/tariffs/add');
                }}
                svg={<NewPlusSvg />}
                width={'140px'}
                sizeText={'small'}
                text={'Добавить'}
              />
            </div>
            <div className={styles.subTitleContainer}>
              <SubTitleMain subTitle={'Тарифы поставщиков и перевозчиков'} />
            </div>
          </div>
        </div>
        <>
          <div className={styles.inputsAndSelectorsContainer}>
            <div style={{ flex: 1 }}>
              <InputSearch
                placeholder={'Поиск по id, компании, материалу'}
                onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                value={filter.search}
              />
            </div>
            <SortBox
              sortOptions={tariffsSortOptions}
              openSort={openSort}
              setOpenSort={setOpenSort}
              sort={sort}
              setSort={setSort}
            />
            <FilterBox
              clearFilters={clearFilters}
              openFilter={openFilter}
              setOpenFilter={setOpenFilter}
              disabled={isDisabledFilterButton}
            >
              <AdminTariffsFilter
                filter={filter}
                setFilter={setFilter}
                organizationOptions={organizationOptions}
                materialOptions={materialOptions}
                periodOptions={periodOptions}
              />
            </FilterBox>
          </div>
          {loading ? (
            <LoadingSpin />
          ) : (
            <div className={styles.customTableContainer}>
              <ConfigProvider
                renderEmpty={() => (
                  <div>
                    {loading ? <div style={{ height: '330px' }} /> : <EmptyList title={'Данных нет'} subTitle={''} />}
                  </div>
                )}
              >
                <Table
                  onRow={record => {
                    return {
                      onClick: () => navigate(`/tariffs/${record.id}`),
                    };
                  }}
                  className={'tariffs-table'}
                  pagination={false}
                  loading={{ indicator: <Spin />, spinning: loading }}
                  columns={columnsForTableOfTariffs}
                  dataSource={tariffs}
                />
              </ConfigProvider>
            </div>
          )}
        </>
      </div>
    </PageWrapper>
  );
};
