import React, { useContext, useState, useEffect } from 'react';
import styles from './AdminUsersPage.module.scss';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import { PageWrapper } from 'src/components/PageWrapper';
import { useMediaQuery } from 'react-responsive';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import { ConfigProvider, Spin, Table } from 'antd';
import { EmptyList } from 'src/components/EmptyList';
import SelectLk from 'src/components/newUI/SelectLk/SelectLk';
import { toastError } from '../../../common/toastError.helper';
import { usePageScroll } from 'src/CustomHooks/usePageScroll';
import { clearObject } from 'src/common/ClearObject.helper';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import useDebounce from 'src/CustomHooks/useDebounce';
import { MobileAdminUsersPage } from './components/MobileAdminUsersPage/MobileAdminUsersPage';
import { IDataUsers, TFilter } from './types';
import { columnsForTableOfCompanies, roleOptions } from './constants';

const limit: number = 10;

export const AdminUsersPage = () => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const initialStateSearch =
    localStorage.getItem('rootSearchField') === 'null' ? '' : localStorage.getItem('rootSearchField');
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<TFilter>({ search: '', typeOfUser: '' });
  const debouncedSearchTerm = useDebounce(filter.search, 500);
  const [companies, setCompanies] = useState<IDataUsers[]>([]);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const getCompanies = async (isPagination: boolean) => {
    if (isFirstRender && initialStateSearch) {
      return;
    }
    try {
      setLoading(true);
      const response = await fetchGet(
        '/admin/users/list',
        clearObject({
          limit: limit,
          offset: isPagination ? offset : 0,
          type: filter.typeOfUser || undefined,
          searchString: filter.search || undefined,
        }),
      );
      setTotalCount(response?.count);
      if (toastError(response)) return;
      setCompanies(prevState => prevState.concat(response?.rows));
    } catch (e) {
      console.log(e);
    } finally {
      setIsFirstRender(false);
      setLoading(false);
    }
  };
  const handleBlockChange = (id: number) => {
    setCompanies(prevState =>
      prevState.map(company => (company.id === id ? { ...company, block: !company.block } : company)),
    );
  };

  usePageScroll(setOffset, limit);

  useEffect(() => {
    setFilter(prevState => ({
      ...prevState,
      search: initialStateSearch,
    }));
  }, []);

  useEffect(() => {
    if (totalCount > offset) {
      getCompanies(true);
    }
  }, [offset]);

  useEffect(() => {
    if (companies.length) setCompanies([]);
    if (offset !== 0) setOffset(0);
    localStorage.setItem('rootSearchField', filter.search);
    getCompanies(false);
  }, [filter.typeOfUser, debouncedSearchTerm]);
  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.container}>
          <div className={styles.titleAndSubtitleContainer}>
            <TitleMain title={'Панель администратора'} />
            <div className={styles.subTitleContainer}>
              <SubTitleMain subTitle={'Настройки администратора'} />
            </div>
          </div>
        </div>
        <>
          <div className={styles.inputsAndSelectorsContainer}>
            <div style={{ flex: 1 }}>
              <InputSearch
                placeholder={'Поиск'}
                onInput={({ currentTarget: { value: search } }) => setFilter(prevState => ({ ...prevState, search }))}
                value={filter.search}
              />
            </div>
            <SelectLk
              options={roleOptions}
              width={isMobile ? '100%' : '240px'}
              value={filter.typeOfUser}
              setValue={value =>
                setFilter(prevState => ({
                  ...prevState,
                  typeOfUser: value === roleOptions[0].value ? '' : value,
                }))
              }
              placeholder="Выбор роли"
            />
          </div>
          {loading ? (
            <LoadingSpin />
          ) : (
            <>
              {isMobile ? (
                <MobileAdminUsersPage companies={companies} changeBlock={handleBlockChange} />
              ) : (
                <div className={styles.customTableContainer}>
                  <ConfigProvider
                    renderEmpty={() => (
                      <div>
                        {loading ? (
                          <div style={{ height: '330px' }} />
                        ) : (
                          <EmptyList title={'Данных нет'} subTitle={''} />
                        )}
                      </div>
                    )}
                  >
                    <Table
                      rowClassName={styles.row}
                      pagination={false}
                      loading={{ indicator: <Spin />, spinning: loading }}
                      columns={columnsForTableOfCompanies(handleBlockChange)}
                      dataSource={companies}
                    />
                  </ConfigProvider>
                </div>
              )}
            </>
          )}
        </>
      </div>
    </PageWrapper>
  );
};
