import { configureStore } from '@reduxjs/toolkit';
import { employeesSlice } from './Pages/EmployeesPage/employeesSlice';
import {materialSlice} from "./Pages/ShowcasesPage/materialSlice";

export const store = configureStore({
  reducer: {
    employees: employeesSlice.reducer,
    material: materialSlice.reducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
