import styles from '../constants.module.scss';
import React from 'react';
import cx from 'classnames';
import { contractorRoles, IPeriodOptions, ISelectValue, ITariff, period } from './types';
import dayjs from 'dayjs';

export const columnsForTableOfTariffs = [
  {
    title: 'id',
    dataIndex: 'id',
    width: 100,
    key: 'id',
    render: text => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={cx(styles.columnContainer, styles.firstColumnContainer)} style={{ width: '100px' }}>
            <p className={styles.tableText}>{text || 'Нет данных'}</p>
          </div>
          <div className={styles.smallBorder}></div>
        </div>
      );
    },
  },
  {
    title: 'Организатор',
    dataIndex: 'organization',
    width: 160,
    key: 'organization',
    render: text => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={styles.columnContainer} style={{ width: '160px' }}>
            <p className={styles.tableText} style={{ maxWidth: '160px' }}>
              {text?.title || 'Нет данных'}
            </p>
          </div>
          <div className={styles.smallBorder}></div>
        </div>
      );
    },
  },
  {
    title: 'Роль',
    dataIndex: 'company',
    width: 100,
    key: 'company',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '100px' }}>
          <p className={styles.tableText} style={{ maxWidth: '100px' }}>
            {contractorRoles[text?.type.toUpperCase()] || 'Нет данных'}
          </p>
        </div>
        <div className={styles.smallBorder}></div>
      </div>
    ),
  },
  {
    title: 'Контрагент',
    dataIndex: 'company',
    width: 227,
    key: 'company',
    render: text => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '227px' }}>
            <p className={styles.tableText} style={{ maxWidth: '227px' }}>
              {text?.title || 'Нет данных'}
            </p>
            <p className={cx(styles.tableText, styles.secondaryText)} style={{ maxWidth: '227px' }}>
              {`ИНН: ${text?.inn || 'Нет данных'}`}
            </p>
          </div>
          <div className={styles.smallBorder}></div>
        </div>
      );
    },
  },

  {
    title: 'Период действия',
    dataIndex: 'dateFrom',
    width: 168,
    key: 'dateFrom',
    render: (text, data: ITariff) => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '168px' }}>
          <p className={styles.tableText} style={{ maxWidth: '168px' }}>
            {text || data?.dateTo
              ? `${convertTime(new Date(text), false) || 'Нет данных'} - ${convertTime(new Date(data?.dateTo), false) || 'Нет данных'}`
              : 'Нет данных'}
          </p>
        </div>
        <div className={styles.smallBorder}></div>
      </div>
    ),
  },
  {
    title: 'Маржа, %',
    dataIndex: 'margin',
    width: 64,
    key: 'margin',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '64px' }}>
          <p className={styles.tableText} style={{ maxWidth: '64px' }}>
            {text || 0}
          </p>
        </div>
        <div className={styles.smallBorder}></div>
      </div>
    ),
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'user',
    width: 227,
    key: 'user',
    render: text => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '227px' }}>
            <p className={styles.tableText}>{convertTime(new Date(text?.updatedAt), true) || 'Нет данных'}</p>
            <p className={cx(styles.tableText, styles.secondaryText)} style={{ maxWidth: '227px' }}>
              {text?.fullName || 'Нет данных'}
            </p>
          </div>
        </div>
      );
    },
  },
];

const convertTime = (time: Date, isWithTime: boolean) => {
  return dayjs(new Date(time.setHours(time.getHours() - 3))).format(`DD.MM.YYYY ${isWithTime ? 'HH:mm' : ''}`);
};

export const tariffsSortOptions: ISelectValue[] = [
  { value: 'new', label: 'Новые' },
  { value: 'old', label: 'Старые' },
];

export const contractorRoleOptions: ISelectValue[] = [
  { value: contractorRoles.CARRIER, label: contractorRoles.CARRIER },
  { value: contractorRoles.SUPPLIER, label: contractorRoles.SUPPLIER },
];

export const periodOptions: IPeriodOptions[] = [
  { id: 0, title: period.ACTIVE },
  { id: 3, title: period.THREEDAYS },
  { id: 10, title: period.TENDAYS },
  { id: -1, title: period.UNACTIVE },
];

export const initFilterTariffs = {
  search: '',
  organizator: null,
  typeOfContractor: null,
  validityPeriod: null,
  material: null,
};
