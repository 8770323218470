import React, { useState, useEffect } from 'react';
import styles from './AdminQuarriesPage.module.scss';
import TitleMain from 'src/components/UI/TitleAndSubtitle/TitleMain';
import { PageWrapper } from 'src/components/PageWrapper';
import SubTitleMain from 'src/components/UI/TitleAndSubtitle/SubTitleMain';
import LoadingSpin from 'src/components/newUI/LoadingSpin/LoadingSpin';
import { InputSearch } from 'src/components/UI/inputs/InputSearch';
import { ConfigProvider, Spin, Table } from 'antd';
import { EmptyList } from 'src/components/EmptyList';
import { usePageScroll } from 'src/CustomHooks/usePageScroll';
import { clearObject } from 'src/common/ClearObject.helper';
import { fetchGet } from 'src/common/proxy-1C-fetch-auth';
import useDebounce from 'src/CustomHooks/useDebounce';
import { toastError } from 'src/common/toastError.helper';
import CustomRangePicker from 'src/components/newUI/CustomRangePicker/CustomRangePicker';
import { columnsForTableOfQuarries } from './constants';
import { IQuarries } from './types';
import { isLeapYear, monthDays } from 'src/Pages/GSMNewPage/GSMHelpers';
import dayjs from 'dayjs';
import { AddBlockCard } from './components/AddBlockCard/AddBlockCard';

const limit: number = 10;
export const AdminQuarriesPage = () => {
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const initialStateSearch = localStorage.getItem('rootSearchQuarryField') || '';
  const [loading, setLoading] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filter, setFilter] = useState<string>('');
  const debouncedSearchTerm = useDebounce(filter, 500);
  const [quarries, setQuarries] = useState<IQuarries[]>([]);
  const [dateRange, setDateRange] = useState<any>(['', '']);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const getQuarries = async (isPagination: boolean) => {
    if ((isFirstRender && initialStateSearch) || !dateRange[0] || !dateRange[1]) {
      return;
    }
    const dateFromISO = new Date(dateRange[0].$d.setHours(3, 0, 0)).toISOString();
    const dateToISO = new Date(dateRange[1].$d.setHours(26, 59, 59)).toISOString();
    try {
      setLoading(true);
      const response = await fetchGet(
        '/admin/quarries/blocked',
        clearObject({
          limit: limit,
          offset: isPagination ? offset : 0,
          blockFrom: dateFromISO || undefined,
          blockTo: dateToISO || undefined,
          searchString: filter || undefined,
        }),
      );
      setTotalCount(response?.count);
      if (toastError(response)) return;
      setQuarries(prevState => prevState.concat(response?.rows));
    } catch (e) {
      console.log(e);
    } finally {
      setIsFirstRender(false);
      setLoading(false);
    }
  };

  usePageScroll(setOffset, limit);

  useEffect(() => {
    setFilter(initialStateSearch);
  }, []);

  useEffect(() => {
    if (quarries?.length) setQuarries([]);
    if (offset !== 0) setOffset(0);
    localStorage.setItem('rootSearchQuarryField', filter);
    getQuarries(false);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (totalCount > offset && quarries.length) {
      getQuarries(true);
    }
  }, [offset]);

  useEffect(() => {
    const today = new Date();
    if (!dateRange[0]) {
      //последний день месяца (в феврале - 28 или 29)
      const lastDay =
        today.getMonth() === 1
          ? isLeapYear(today.getFullYear())
            ? monthDays[1].to[1]
            : monthDays[1].to[0]
          : monthDays[today.getMonth()]?.to;

      setDateRange([
        dayjs(new Date(new Date(today.setDate(1)).setHours(0, 0, 0))),
        dayjs(new Date(new Date(today.setDate(lastDay)).setHours(23, 59, 59))),
      ]);
    }
    if (offset !== 0) setOffset(0);
    setQuarries([]);
    getQuarries(false);
  }, [dateRange]);
  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.container}>
          <div className={styles.titleAndSubtitleContainer}>
            <TitleMain title={'Карьеры'} />
            <div className={styles.subTitleContainer}>
              <SubTitleMain subTitle={'Настройки администратора'} />
            </div>
          </div>
        </div>
        <AddBlockCard />
        <>
          <div className={styles.inputsAndSelectorsContainer}>
            <div style={{ flex: 1 }}>
              <InputSearch
                placeholder={'Поиск'}
                onInput={({ currentTarget: { value: search } }) => setFilter(search)}
                value={filter}
              />
            </div>
            <CustomRangePicker
              width={'244px'}
              isPresets={true}
              open={openCalendar}
              dateRange={dateRange}
              setDateRange={setDateRange}
              setOpen={setOpenCalendar}
              placeholder={['Начало', 'Конец']}
              height="40px"
              isNewStyle={true}
            />
          </div>
          {loading ? (
            <LoadingSpin />
          ) : (
            <div className={styles.customTableContainer}>
              <ConfigProvider
                renderEmpty={() => (
                  <div>
                    {loading ? <div style={{ height: '330px' }} /> : <EmptyList title={'Данных нет'} subTitle={''} />}
                  </div>
                )}
              >
                <Table
                  rowClassName={styles.row}
                  pagination={false}
                  loading={{ indicator: <Spin />, spinning: loading }}
                  columns={columnsForTableOfQuarries}
                  dataSource={quarries}
                />
              </ConfigProvider>
            </div>
          )}
        </>
      </div>
    </PageWrapper>
  );
};
