import styles from '../constants.module.scss';
import React from 'react';
import cx from 'classnames';
import { IQuarries, TInputInfo } from './types';
import dayjs from 'dayjs';
import ModalQuarryBlocking from './components/ModalQuarryBlocking/ModalQuarryBlocking';

export const columnsForTableOfQuarries = [
  {
    title: 'Наименование карьера',
    dataIndex: 'quarryTitle',
    width: 260,
    key: 'quarryTitle',
    render: text => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={cx(styles.columnContainer, styles.firstColumnContainer)} style={{ width: '284px' }}>
            <p className={styles.tableText}>{text || 'Нет данных'}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      );
    },
  },
  {
    title: 'Заблокирован для',
    dataIndex: 'blockTypeTitle',
    width: 240,
    key: 'blockTypeTitle',
    render: (text, data: IQuarries) => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '240px' }}>
            <p className={styles.tableText} style={{ maxWidth: '240px' }}>
              {text || 'Нет данных'}
            </p>
            <p
              className={cx(styles.tableText, styles.secondaryText)}
              style={{ maxWidth: '240px' }}
            >{`Тип: ${typesOfBlock.find(item => item?.value === data?.blockType)?.label || 'Нет данных'}`}</p>
          </div>
          <div className={styles.border}></div>
        </div>
      );
    },
  },
  {
    title: 'Дата начала и конца блокировки',
    dataIndex: 'blockFrom',
    width: 192,
    key: 'blockFrom',
    render: (text, data: IQuarries) => <ModalQuarryBlocking text={text} data={data} />,
  },
  {
    title: 'Причина блокировки',
    dataIndex: 'blockReasonTitle',
    width: 160,
    key: 'blockReasonTitle',
    render: text => (
      <div className={styles.columnAndBorder}>
        <div className={styles.columnContainer} style={{ width: '160px' }}>
          <p className={styles.tableText} style={{ maxWidth: '160px' }}>
            {text || 'Нет данных'}
          </p>
        </div>
        <div className={styles.border}></div>
      </div>
    ),
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'lastChangeBy',
    width: 194,
    key: 'lastChangeBy',
    render: (text, data: IQuarries) => {
      return (
        <div className={styles.columnAndBorder}>
          <div className={cx(styles.columnContainer, styles.twoRows)} style={{ width: '194px' }}>
            <p className={styles.tableText}>{dayjs(data?.lastChangeDate).format('DD.MM.YYYY HH:mm') || 'Нет данных'}</p>
            <p className={cx(styles.tableText, styles.secondaryText)} style={{ maxWidth: '194px' }}>
              {text || 'Нет данных'}
            </p>
          </div>
        </div>
      );
    },
  },
];

export const addBlockCardTitles = [
  'Выберите карьер из списка *',
  'Выберите материал',
  'Тип для блокировки',
  'Заблокирован для',
  'Начало блокировки (Дата/время) *',
  'Конец блокировки (Дата/время) *',
  'Причина блокировки',
];

export enum typesOfInput {
  SELECT = 'select',
  DATETIME = 'datetime',
}
const typesOfBlock = [
  { value: 'all', label: 'Все' },
  { value: 'buyer', label: 'Клиент' },
  { value: 'object', label: 'Объект' },
  { value: 'carrier', label: 'Перевозчик' },
];
export const initInputsData: TInputInfo[] = [
  {
    name: 'quarry',
    type: typesOfInput.SELECT,
    placeholder: 'Выберите карьер из списка',
    value: '',
    options: [],
    isSearch: true,
  },
  {
    name: 'material',
    type: typesOfInput.SELECT,
    placeholder: 'Выберите материал из списка',
    value: '',
    options: [],
    isSearch: false,
  },
  {
    name: 'blockType',
    type: typesOfInput.SELECT,
    placeholder: 'Все/Клиент/Объект/Перевозчик',
    value: 'all',
    options: typesOfBlock,
    isSearch: false,
  },
  {
    name: 'object',
    type: typesOfInput.SELECT,
    placeholder: 'Выберите объект из списка',
    value: '',
    options: [],
    isSearch: true,
  },
  { name: 'dateFrom', type: typesOfInput.DATETIME, date: '', time: '', open: false },
  { name: 'dateTo', type: typesOfInput.DATETIME, date: '', time: '', open: false },
  {
    name: 'reason',
    type: typesOfInput.SELECT,
    placeholder: 'Выберите из списка',
    value: '',
    options: [],
    isSearch: false,
  },
];
export const hoursArray = () => {
  return Array.from({ length: 24 }, (el, i) => {
    return { value: `${i < 10 ? '0' : ''}${i}:00`, label: `${i < 10 ? '0' : ''}${i}:00` };
  });
};
