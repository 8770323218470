import { useEffect } from 'react';

export const usePageScroll = (setOffset: React.Dispatch<React.SetStateAction<number>>, limit: number) => {
  useEffect(() => {
    let isScroll = true;
    const handleScroll = e => {
      if (
        e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) < 160 &&
        isScroll
      ) {
        setOffset(prevState => prevState + limit);
        isScroll = false;
      }
      if (e.target.documentElement.scrollHeight - (e.target.documentElement.scrollTop + window.innerHeight) > 160) {
        isScroll = true;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
};
