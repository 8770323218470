import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../CustomHooks/storeHooks";
import {
    getAllMaterials,
    getCurrentMaterialProperties,
    selectCurrentMaterialProperties,
    selectMaterialTypes
} from "../materialSlice";
import {PageWrapper} from "../../../components/PageWrapper";
import styles from './EditAndAddMaterialPage.module.scss'
import {IMaterialType} from "../type";

const EditAndAddMaterialPage = () => {
    const materialTypes=useAppSelector(selectMaterialTypes)
    const currentMaterialProperties=useAppSelector(selectCurrentMaterialProperties)
    const dispatch =useAppDispatch()
    useEffect(()=>{
        dispatch(getAllMaterials())
    },[])
    return (
        <PageWrapper>
            <h1>Добавление материала</h1>
            <p>Выберите породу материала</p>

            <div className={styles.materialTypesContainer}>
                {materialTypes.map((materialType:IMaterialType)=>(
                    <div onClick={()=>{dispatch(getCurrentMaterialProperties(materialType.id))}} 
                         className={styles.materialTypeContainer}>
                        <div  className={materialType.active ?styles.materialTypeImgActive:styles.materialTypeImgBorder}>
                            <img src={materialType.imageUrl} alt="фото типа материала"/>
                        </div>
                        <span className={materialType.active ?styles.materialTypeTitleActive:styles.materialTypeTitle} >{materialType.title}</span>
                    </div>

                    ))}
            </div>
          
        </PageWrapper>
    );
};

export default EditAndAddMaterialPage;