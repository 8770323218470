import React, { useRef, useState } from 'react';
import styles from './ShowMoreAdminTariffs.module.scss';
import { ShowMoreSVG } from '../../../../../components/UI/svg-icon/ShowMoreSvg';
import useClickOutsideNode from '../../../../../CustomHooks/useClickOutsideNode';
import { IArrayButtonsShowMoreAdminTariffs } from '../../type';
import { arrayButtons } from '../../const';
const ShowMoreAdminTariffs = () => {
  const [openWindow, setOpenWindow] = useState<boolean>(false);
  const refContainer = useRef();
  useClickOutsideNode(refContainer, setOpenWindow);
  return (
    <div ref={refContainer}>
      <div
        onClick={e => {
          e.stopPropagation();
          setOpenWindow(!openWindow);
        }}
        className={styles.svgContainer}
      >
        <ShowMoreSVG />
      </div>
      {openWindow && (
        <div className={styles.windowContainer}>
          {arrayButtons.map(button => {
            return (
              <div key={button.title} onClick={button.onClick} className={styles.windowButtonContainer}>
                <p className={styles.windowButtonText}>{button.title}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ShowMoreAdminTariffs;
