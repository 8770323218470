import React, { useContext, useRef, useState } from 'react';

// @ts-ignore
import styles from './NewAsideNavSupplier.module.css';
import { Context } from 'src/context/context';
import useClickOutside from 'src/CustomHooks/useClickOutside';
import { ShowcasesIconSvg } from 'src/components/UI/svg-icon/ShowcasesIconSvg';
import { ShowcasesIconSvgActive } from 'src/components/UI/svg-icon/ShowcasesIconSvgActive';
import { HelpSvg } from 'src/components/UI/svg-icon/HelpSvg';
import { HelpSvgActive } from 'src/components/UI/svg-icon/HelpSvgActive';
import BurgerMenuSvg from 'src/components/UI/svg-icon/BurgerMenuSvg';
import { NavLink } from 'react-router-dom';
import { logout } from 'src/common/logout.mapping';
import NewNavItemMobile from 'src/components/newUI/NewNavItem/NewNavItemMobile';
import { MapMarkerMultipleSvg } from '../UI/svg-icon/MapMarkerMultipleSvg';
import { MapMarkerMultipleSvgActive } from '../UI/svg-icon/MapMarkerMultipleSvgActive';
import { TariffsSvg } from '../UI/svg-icon/TariffsSvg';
import { TariffsSvgActive } from '../UI/svg-icon/TariffsSvgActive';

const NewAsideNavAdminMobile = () => {
  const [open, setOpen] = useState(false);
  const { user, setUser } = useContext(Context);
  const clickRef = useRef();
  useClickOutside(clickRef, setOpen);
  const adminNavLink = [
    {
      name: 'Панель администратора',
      link: '/panel',
      type: ['root'],
      notActiveProfile: false,
      svg: <ShowcasesIconSvg />,
      svgActive: <ShowcasesIconSvgActive />,
      errorProfile: false,
    },
    {
      name: 'Блокировка карьеров',
      link: '/quarries',
      type: ['root'],
      notActiveProfile: false,
      svg: <MapMarkerMultipleSvg />,
      svgActive: <MapMarkerMultipleSvgActive />,
      errorProfile: false,
    },
    {
      name: 'Тарифы',
      link: '/tariffs',
      type: ['root'],
      notActiveProfile: false,
      svg: <TariffsSvg />,
      svgActive: <TariffsSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
    {
      name: 'Помощь',
      link: '/help',
      type: ['root'],
      notActiveProfile: false,
      disable: true,
      svg: <HelpSvg />,
      svgActive: <HelpSvgActive />,
      svgProfileError: null,
      errorProfile: false,
    },
  ];
  return (
    <div>
      <div className={styles.burgerMobile}>
        <div className={styles.burgerMobileContainer}>
          <div onClick={() => setOpen(!open)} ref={clickRef}>
            <BurgerMenuSvg />
          </div>
          <NavLink to="/">
            <div className={styles.exitContainer} onClick={() => logout(user, setUser)}>
              <p
                style={{
                  marginRight: '8px',
                }}
              >
                Выйти
              </p>
              <svg
                className="ButtonExit__svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 0H2C0.89 0 0 0.89 0 2V6H2V2H16V16H2V12H0V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V2C18 0.89 17.1 0 16 0ZM7.08 12.58L8.5 14L13.5 9L8.5 4L7.08 5.41L9.67 8H0V10H9.67L7.08 12.58Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          </NavLink>
        </div>
      </div>
      {open && (
        <div className={styles.AsideBoxMobile}>
          <div className={styles.AsideBoxMobileContainer}>
            {adminNavLink.map(
              ({ name, type, link, notActiveProfile, svg, svgActive, svgProfileError, errorProfile }) => {
                return (
                  <NewNavItemMobile
                    onClick={() => setOpen(false)}
                    disabled={null}
                    typeAccess={type}
                    key={name}
                    name={name}
                    link={link}
                    notActiveProfile={notActiveProfile}
                    svg={svg}
                    svgActive={svgActive}
                    svgProfileError={svgProfileError}
                    errorProfile={errorProfile}
                  />
                );
              },
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewAsideNavAdminMobile;
