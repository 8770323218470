import React, { useState } from 'react';
import styles from './AdminTariffsAddPage.module.scss';
import { PageWrapper } from '../../../components/PageWrapper';
import BackButton from '../../../components/newUI/BackButton/BackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import TitleMain from '../../../components/UI/TitleAndSubtitle/TitleMain';
import ButtonFilled from '../../../components/newUI/ButtonFilled/ButtonFilled';
import {
  carrierColumnsOfSupplier,
  carrierParametersOfAddTariffs,
  currentSiteEnum,
  dataResponseTableMock,
  mainParametersOfAddTariffs,
  objectCompare,
  requestDataAddTariff,
  supplierColumnsOfSupplier,
  supplierParametersOfAddTariffs,
} from './const';
import { IParametersOfAddTariffs } from './type';
import { ImageContainer } from '../../../components/ImageContainer';
import { ProfilePageUploading } from '../../../components/ProfilePageUploading';
import { ButtonClose } from '../../../components/UI/buttons/buttonClose';
import { removeImage } from '../../../common/removeImage';
import { toastError } from '../../../common/toastError.helper';
import { closePopUp, openPopUp } from '../../../common/open-close-popup.function';
import InputsMapping from './components/InputsMapping/InputsMapping';
import { Image } from '../../../common/image.type';
import { fetchPost } from '../../../common/proxy-1C-fetch-auth';
import CustomTable from '../../../components/newUI/CustomTable/CustomTable';
import ButtonNotFilled from '../../../components/newUI/ButtonNotFilled/ButtonNotFilled';
import { CopySvg } from '../../../components/UI/svg-icon/CopySvg';
import { ButtonSimple } from '../../../components/newUI/ButtonSimple/ButtonSimple';
import { PlusSvg } from '../../../components/UI/svg-icon/PlusSvg';
interface IProps {
  currentSite: string;
}
const AdminTariffsAddPage = ({ currentSite }: IProps) => {
  const [loadingAddTariff, setLoadingAddTariff] = useState<boolean>(false);
  const [dataResponseTable, setDataResponseTable] = useState<any>(dataResponseTableMock);
  const [mainParametersInfo, setMainParametersInfo] = useState<IParametersOfAddTariffs[]>(mainParametersOfAddTariffs);
  const [carrierParametersInfo, setCarrierParametersInfo] =
    useState<IParametersOfAddTariffs[]>(carrierParametersOfAddTariffs);
  const [supplierParametersInfo, setSupplierParametersInfo] =
    useState<IParametersOfAddTariffs[]>(supplierParametersOfAddTariffs);
  const [imagesScan, setImagesScan] = useState<Image[]>([]);
  const [uploadImagesScan, setUploadImagesScan] = useState<Image[]>([]);
  const [showImage, setShowImage] = useState<string>('');
  const [popUpActiveImg, setPopUpActiveImg] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let currentId: string = currentSite === currentSiteEnum.ID ? pathname.split('/')[2] : '';
  const currentRole: string = mainParametersInfo[1].value;
  const openPopupWithImg = src => {
    setShowImage(src);
    setPopUpActiveImg(openPopUp);
  };
  const addTariff = async () => {
    setLoadingAddTariff(true);
    try {
      const responseAddTariff = await fetchPost(
        '/admin/tariffs',
        'POST',
        requestDataAddTariff(currentRole, mainParametersInfo, carrierParametersInfo, supplierParametersInfo),
      );
      if (toastError(responseAddTariff)) return;
      navigate(`/tariffs/${responseAddTariff.response.id}`);
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingAddTariff(false);
    }
  };
  const deleteFile = async (id: string, name: string, communicationCategory: string) => {
    try {
      const response = await fetchPost(`/files/${id}`, 'DELETE', {
        communicationCategory: communicationCategory,
        name: name,
        objectUuid: '',
      });
      const data = await response.json();
      if (toastError(data)) return;
    } catch (e) {
      console.log(e);
    }
  };
  const objectCompareColumnsByRole = {
    carrier: carrierColumnsOfSupplier,
    supplier: supplierColumnsOfSupplier,
  };
  const objectCompareStateByRole = {
    carrier: carrierParametersInfo,
    supplier: supplierParametersInfo,
  };
  const keysForDisable = (valueOfObject: IParametersOfAddTariffs) => {
    if (valueOfObject.required === false) {
      return false;
    }
    return (
      valueOfObject.value === '' ||
      valueOfObject.valueTo === '' ||
      valueOfObject.valueFrom === '' ||
      (valueOfObject.dateRange && valueOfObject.dateRange.includes(''))
    );
  };
  const disableSaveButton = (): boolean => {
    return (
      !!mainParametersInfo.find(item => keysForDisable(item)) ||
      !!objectCompareStateByRole[currentRole]?.find(item => keysForDisable(item)) ||
      loadingAddTariff
    );
  };
  return (
    <PageWrapper>
      <div className={styles.topContainer}>
        <BackButton textButton={'Вернуться к тарифам'} onClick={() => navigate('/tariffs')} />
        <div className={styles.titleAndSaveButtonContainer}>
          <TitleMain fontSize={'24px'} title={objectCompare[currentSite]} />
          <div className={styles.buttonsContainer}>
            {currentSite === currentSiteEnum.ID && (
              <ButtonNotFilled svg={<CopySvg />} width={'158px'} text={'Создать копию'} sizeText={'small'} />
            )}
            <ButtonFilled
              sizeText={'small'}
              onClick={addTariff}
              text={'Сохранить'}
              width={'140px'}
              disabled={disableSaveButton()}
            />
          </div>
        </div>
      </div>
      <InputsMapping
        carrierParametersInfo={carrierParametersInfo}
        setCarrierParametersInfo={setCarrierParametersInfo}
        supplierParametersInfo={supplierParametersInfo}
        setSupplierParametersInfo={setSupplierParametersInfo}
        mainParametersInfo={mainParametersInfo}
        setMainParametersInfo={setMainParametersInfo}
        role={'main'}
      />
      <div style={{ width: '350px' }} className="DriverPagePopup__inputItem">
        <ProfilePageUploading
          limitSize={30}
          name={'Прикрепить файл скана спецификации'}
          desc={''}
          setImages={setUploadImagesScan}
          images={uploadImagesScan}
          count={3 - imagesScan?.length}
        />
        {imagesScan?.map(({ id, location, name, size, isSize, date, isDate, mimeType }) => (
          <div className="image-container" key={id}>
            <ImageContainer
              id={id}
              value={mimeType === 'image/png' || mimeType === 'image/jpeg' ? '' : location}
              name={name}
              size={size}
              isSize={isSize}
              date={date}
              isDate={isDate}
              type={mimeType}
              openPopUp={() => (mimeType === 'image/png' || mimeType === 'image/jpeg') && openPopupWithImg(location)}
            >
              <ButtonClose
                id={id}
                onClick={({ target }) => {
                  deleteFile(id, name, 'cars');
                  removeImage(target, imagesScan, setImagesScan);
                }}
                hidden=""
              />
            </ImageContainer>
          </div>
        ))}
        {uploadImagesScan?.map(({ id, value, name, size, isSize, date, isDate, type }) => (
          <div key={id}>
            <ImageContainer
              id={id}
              value={type === 'image/png' || type === 'image/jpeg' ? '' : value}
              name={name}
              size={size}
              isSize={isSize}
              date={date}
              isDate={isDate}
              type={type}
              openPopUp={() => (type === 'image/png' || type === 'image/jpeg') && openPopupWithImg(value)}
            >
              <ButtonClose
                id={id}
                onClick={({ target }) => removeImage(target, uploadImagesScan, setUploadImagesScan)}
                hidden=""
              />
            </ImageContainer>
          </div>
        ))}
      </div>
      <div className={popUpActiveImg ? 'Popup active' : 'Popup'}>
        <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__white"></div>
        <div className="Popup__wrapper">
          <div>
            <div>
              <div onClick={() => setPopUpActiveImg(closePopUp)} className="Popup__close"></div>
              <div className="Popup__content">
                <div className="Popup__box">
                  <img src={showImage} alt={'скан спецификации'} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.subTitleContainer}>
        {currentSite === currentSiteEnum.ADD && (
          <>
            <div className={styles.idSubtitleContainer}>
              <p className={styles.subTitleText}>Параметры</p>
            </div>
            <InputsMapping
              carrierParametersInfo={carrierParametersInfo}
              setCarrierParametersInfo={setCarrierParametersInfo}
              supplierParametersInfo={supplierParametersInfo}
              setSupplierParametersInfo={setSupplierParametersInfo}
              mainParametersInfo={mainParametersInfo}
              setMainParametersInfo={setMainParametersInfo}
              role={currentRole}
            />
          </>
        )}
        {currentSite === currentSiteEnum.ID && (
          <>
            <div className={styles.idSubtitleContainer}>
              <p className={styles.subTitleText}>Спецификации к тарифу</p>
              <ButtonSimple text={'Добавить спецификацию'} svg={<PlusSvg width={'14px'} />} />
            </div>
            <div className={styles.customTableContainer}>
              <CustomTable columns={objectCompareColumnsByRole[currentRole]} data={dataResponseTable} />
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default AdminTariffsAddPage;
