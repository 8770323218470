import React, { useEffect, useLayoutEffect } from 'react';
import styles from '../../AdminTariffsAddPage.module.scss';
import {
  carrierParametersOfAddTariffs,
  currentStyleByRole,
  nameOfParameters,
  supplierParametersOfAddTariffs,
} from '../../const';
import SelectLk from '../../../../../components/newUI/SelectLk/SelectLk';
import CustomRangePicker from '../../../../../components/newUI/CustomRangePicker/CustomRangePicker';
import CustomInput from '../../../../../components/newUI/CustomInput/CustomInput';
import { regNumber } from '../../../../../common/number.helper';
import { CheckBoxCustom } from '../../../../../components/UI/checkBox/CheckBoxCustom';
import {
  IChangeFieldOfInputWithValidation,
  IChangeFieldOfSelector,
  IOptions,
  IParametersOfAddTariffs,
} from '../../type';
import { EmptyList } from '../../../../../components/EmptyList';
interface IProps {
  mainParametersInfo: IParametersOfAddTariffs[];
  setMainParametersInfo: React.Dispatch<React.SetStateAction<IParametersOfAddTariffs[]>>;
  carrierParametersInfo: IParametersOfAddTariffs[];
  setCarrierParametersInfo: React.Dispatch<React.SetStateAction<IParametersOfAddTariffs[]>>;
  supplierParametersInfo: IParametersOfAddTariffs[];
  setSupplierParametersInfo: React.Dispatch<React.SetStateAction<IParametersOfAddTariffs[]>>;
  role: string;
}

const InputsMapping = ({
  mainParametersInfo,
  setMainParametersInfo,
  carrierParametersInfo,
  setCarrierParametersInfo,
  supplierParametersInfo,
  setSupplierParametersInfo,
  role,
}: IProps) => {
  const objectCompareForStateByRole = {
    main: mainParametersInfo,
    carrier: carrierParametersInfo,
    supplier: supplierParametersInfo,
    '': [{}] /*Если ничего не выбрано, то выводим сообщение вместо инпутов*/,
  };
  const objectCompareForSetStateByRole = {
    main: setMainParametersInfo,
    carrier: setCarrierParametersInfo,
    supplier: setSupplierParametersInfo,
  };
  const valueCostPrice: number = Number(
    objectCompareForStateByRole[role].find(item => item.name === nameOfParameters.COST_PRICE)?.value,
  );
  const valueExtraCharge: number = Number(
    objectCompareForStateByRole[role].find(item => item.name === nameOfParameters.EXTRA_CHARGE)?.value,
  );
  const pickCheckPrice = (id: string): void => {
    objectCompareForSetStateByRole[role](prevState =>
      prevState.map(input => {
        if (input.name === id) {
          return {
            ...input,
            check: !input.check,
          };
        }
        return input;
      }),
    );
  };
  const inputValue = (parameter: IParametersOfAddTariffs): string => {
    if (parameter.name === nameOfParameters.TOTAL_AMOUNT)
      return ((valueCostPrice * (valueExtraCharge + 100)) / 100).toFixed(0);
    return parameter.value;
  };
  const changeFieldOfInputWithValidation = ({
    nameObject,
    fieldOfObject,
    valueField,
    validation,
  }: IChangeFieldOfInputWithValidation): void => {
    objectCompareForSetStateByRole[role](prevInputsInfo =>
      prevInputsInfo.map(input => {
        if (input.name === nameObject && validation) {
          return {
            ...input,
            [fieldOfObject]: valueField,
          };
        }
        return input;
      }),
    );
  };
  const changeFieldOfSelector = ({ nameObject, fieldOfObject, valueField, clearValue }: IChangeFieldOfSelector) => {
    objectCompareForSetStateByRole[role](prevInputsInfo =>
      prevInputsInfo.map(input => {
        if (input.name === nameObject && !clearValue) {
          return {
            ...input,
            [fieldOfObject]: valueField as string | string[] | boolean | void,
          };
        }
        if (input.name === nameObject && clearValue) {
          return { ...input, value: '', options: valueField as IOptions[] };
        }
        return input;
      }),
    );
  };
  useEffect(() => {
    setCarrierParametersInfo(carrierParametersOfAddTariffs);
    setSupplierParametersInfo(supplierParametersOfAddTariffs);
  }, [role]);
  return (
    <div className={styles.mainParametersInfoContainer}>
      {objectCompareForStateByRole[role]?.map(parameter => {
        const parameterClassname = currentStyleByRole(parameter.name)[role];
        switch (parameter.typeComponent) {
          case 'select':
            return (
              <div className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <SelectLk
                  width={'100%'}
                  isSearch={parameter.isSearch}
                  heightSelectWindow={'195px'}
                  options={parameter.options}
                  value={parameter?.value}
                  placeholder={parameter.placeholder}
                  setValue={value => {
                    changeFieldOfSelector({
                      nameObject: parameter.name,
                      fieldOfObject: 'value',
                      valueField: value,
                      clearValue: false,
                    });
                  }}
                />
              </div>
            );
          case 'rangePicker':
            return (
              <div className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <CustomRangePicker
                  width={'100%'}
                  placeholder={['Начало', 'Конец']}
                  isPresets={true}
                  open={parameter.open}
                  dateRange={parameter.dateRange}
                  setDateRange={value => {
                    changeFieldOfSelector({
                      nameObject: parameter.name,
                      fieldOfObject: 'dateRange',
                      valueField: value,
                      clearValue: false,
                    });
                  }}
                  setOpen={value => {
                    changeFieldOfSelector({
                      nameObject: parameter.name,
                      fieldOfObject: 'open',
                      valueField: value as boolean,
                      clearValue: false,
                    });
                  }}
                />
              </div>
            );
          case 'input':
            return (
              <div className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <CustomInput
                  width={'100%'}
                  suffix={parameter.suffix}
                  setValue={value => {
                    changeFieldOfInputWithValidation({
                      nameObject: parameter.name,
                      fieldOfObject: 'value',
                      valueField: value,
                      validation: (regNumber.test(value) && value.length <= parameter.limitSymbol) || value === '',
                    });
                  }}
                  value={inputValue(parameter)}
                  placeholder={parameter.placeholder}
                  disabled={parameter.disabled}
                />
              </div>
            );
          case 'checkbox':
            return (
              <div className={parameterClassname}>
                <CheckBoxCustom
                  children={parameter.title}
                  error={false}
                  check={parameter.check}
                  pick={pickCheckPrice}
                  name={parameter.name}
                  id={parameter.name}
                />
              </div>
            );
          case 'rangeInput':
            return (
              <div className={parameterClassname}>
                <p className={styles.parameterTitle}>
                  {parameter.title}
                  {parameter.required && <span style={{ color: 'var(--color-red600)' }}>{' ' + '*'}</span>}
                </p>
                <div className={styles.rangeInputContainer}>
                  <CustomInput
                    width={'103px'}
                    suffix={parameter.suffix}
                    setValue={value => {
                      changeFieldOfInputWithValidation({
                        nameObject: parameter.name,
                        fieldOfObject: 'valueFrom',
                        valueField: value,
                        validation: (regNumber.test(value) && value.length <= 10) || value === '',
                      });
                    }}
                    value={parameter.valueFrom}
                    placeholder={parameter.placeholder}
                    disabled={false}
                  />
                  <div className={styles.divider} />
                  <CustomInput
                    width={'103px'}
                    suffix={parameter.suffix}
                    setValue={value => {
                      changeFieldOfInputWithValidation({
                        nameObject: parameter.name,
                        fieldOfObject: 'valueTo',
                        valueField: value,
                        validation: (regNumber.test(value) && value.length <= 10) || value === '',
                      });
                    }}
                    value={parameter.valueTo}
                    placeholder={parameter.placeholder}
                    disabled={false}
                  />
                </div>
              </div>
            );
          default:
            return (
              <div className={styles.emptyListContainer}>
                <EmptyList title={'Выберите роль для отображения ограничений'} subTitle={'Роль не выбрана'} />
              </div>
            );
        }
      })}
    </div>
  );
};

export default InputsMapping;
