// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ShowMoreAdminTariffs_svgContainer__9NSgt {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.ShowMoreAdminTariffs_windowContainer__AnCo\\+ {\n  position: absolute;\n  right: 0;\n  margin-top: 13px;\n  display: flex;\n  width: 179px;\n  padding-right: 8px;\n  flex-direction: column;\n  border-radius: 8px;\n  border: 1px solid var(--color-gray200);\n  background: var(--color-white);\n  box-shadow: 0 4px 20px 0 rgba(138, 136, 255, 0.08);\n}\n\n.ShowMoreAdminTariffs_windowButtonContainer__l465r {\n  display: flex;\n  align-items: center;\n  padding: 12px;\n}\n\n.ShowMoreAdminTariffs_windowButtonText__P-SUJ {\n  color: var(--color-gray800);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  -webkit-user-select: none;\n          user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/Pages/AdminPages/AdminTariffsAddPage/components/ShowMoreAdminTariffs/ShowMoreAdminTariffs.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AACA;EACE,kBAAA;EACA,QAAA;EACA,gBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EAEA,kBAAA;EACA,sCAAA;EACA,8BAAA;EACA,kDAAA;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;AAEF;;AAAA;EACE,2BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,yBAAA;UAAA,iBAAA;AAGF","sourcesContent":[".svgContainer{\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n.windowContainer{\n  position: absolute;\n  right: 0;\n  margin-top: 13px;\n  display: flex;\n  width: 179px;\n  padding-right: 8px;\n  flex-direction: column;\n\n  border-radius: 8px;\n  border: 1px solid var(--color-gray200);\n  background: var(--color-white);\n  box-shadow: 0 4px 20px 0 rgba(138, 136, 255, 0.08);\n}\n.windowButtonContainer{\n  display: flex;\n  align-items: center;\n  padding: 12px;\n}\n.windowButtonText{\n  color: var(--color-gray800);\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"svgContainer": "ShowMoreAdminTariffs_svgContainer__9NSgt",
	"windowContainer": "ShowMoreAdminTariffs_windowContainer__AnCo+",
	"windowButtonContainer": "ShowMoreAdminTariffs_windowButtonContainer__l465r",
	"windowButtonText": "ShowMoreAdminTariffs_windowButtonText__P-SUJ"
};
export default ___CSS_LOADER_EXPORT___;
