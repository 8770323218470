import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {getMaterialProperties, getMaterials} from "./materialApi";
import {RootState} from "../../store";
import {IMaterialType} from "./type";


export const getAllMaterials = createAsyncThunk(
    'employees/getAllMaterials',
    async () => {
        const response = await getMaterials();
        return response.data;
    },
);export const getCurrentMaterialProperties = createAsyncThunk(
    'employees/getCurrentMaterial',
    async (id :number) => {
        const response = await getMaterialProperties(id);
        return response.data;
    },
);
export interface MaterialState {
    materialTypes: IMaterialType[]
    currentMaterialProperties:any
}

const initialState: MaterialState = {
    materialTypes: [],
    currentMaterialProperties:null,
}

export const materialSlice = createSlice({
    name: 'material',
    initialState,
    reducers: {
    },
    extraReducers:(builder)=>{
        builder.addCase(getAllMaterials.fulfilled,(state,action)=>{
            state.materialTypes=action.payload.response.map((material)=> {return{...material,active:false}})
        })
        builder.addCase(getCurrentMaterialProperties.fulfilled,(state,action)=>{
            state.currentMaterialProperties=action.payload.response
            state.materialTypes=state.materialTypes.map((type)=>{
                return{...type,active:action.meta.arg===type.id}
            })
        })
    }
})


export const selectMaterialTypes = (state: RootState) => state.material.materialTypes;
export const selectCurrentMaterialProperties = (state: RootState) => state.material.currentMaterialProperties;
export const { } = materialSlice.actions


