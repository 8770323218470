export interface ISelectValue {
  value: string;
  label: string;
}

export interface IFilterTariffs {
  search: string;
  organizator: IOrganizationOptions;
  typeOfContractor: string;
  validityPeriod: IPeriodOptions;
  material: IMaterialOptions;
}

export interface ITariff {
  id: number;
  organization: { inn: string; title: string };
  type: string;
  company: { title: string; type: string; inn: string };
  dateFrom: string;
  dateTo: string;
  margin: string;
  tariffDetails: ITariffDetails[];
  user: { id: number; fullName: string; updatedAt: string };
}

interface ITariffDetails {
  id: number;
  distanceFrom: number;
  distanceTo: number;
  cost: number;
  margin: number;
  finalPrice: number;
}

export interface IOrganizationOptions {
  id: number;
  title: string;
  inn: string;
}

export interface IMaterialOptions {
  id: number;
  title: string;
}

export interface IPeriodOptions {
  id: number;
  title: string;
}

export enum contractorRoles {
  CARRIER = 'Перевозчик',
  SUPPLIER = 'Поставщик',
}

export enum contractorRolesForBack {
  'Перевозчик' = 'carrier',
  'Поставщик' = 'supplier',
}

export enum sortForBack {
  'Новые' = 'ASC',
  'Старые' = 'DESC',
}

export enum period {
  ACTIVE = 'Действующиие тарифы',
  THREEDAYS = 'Закончатся через 3 дня',
  TENDAYS = 'Закончатся через 10 дней',
  UNACTIVE = 'Неактивные тарифы',
}
